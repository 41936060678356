import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyToastService {

  toastButtonClick: EventEmitter<any> = new EventEmitter();


  emitButtonClick(value:string){
    this.toastButtonClick.emit(value)
  }

}
