import { Injectable, EventEmitter } from '@angular/core';
import * as Plotly from 'plotly.js-dist-min';

@Injectable({
  providedIn: 'root'
})

export class PlotlyService {
  readonly exportEvent = new EventEmitter();
  readonly selectEvent = new EventEmitter();


  genericConfig = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: ['autoScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'lasso2d', 'select2d'],
  };

  emitExport(data) {
    this.exportEvent.emit(data);
  }

  emitSelect(data) {
    this.selectEvent.emit(data);
  }

  getPlotly() {
    return Plotly;
  }

  plotGrafic(plotDiv: any, data: any, layout: any, configExternal?: any) {
    if (configExternal) {
      Plotly.newPlot(plotDiv, data, layout, configExternal);
    } else {
      Plotly.newPlot(plotDiv, data, layout, this.genericConfig);
    }
  }

  reStyle(update, index, id) {
    Plotly.restyle(id || 'chartGenericPlot', update, index); // Atualiza o conjunto de dados
  }

  upDateTooltip(tooltip, id?){
    Plotly.update(id || 'chartGenericPlot', { hovertemplate: tooltip });
  }

  labelUpdate(data) {
    const newData = [];
    data.forEach((item, index) => {
      newData.push(item + '-B-')
      newData.push(item)
      newData.push(item + '-A-')
    })
    return newData;
  }

  barUpdate(data) {
    const newData = [];
    data.forEach((item, index) => {
      newData.push(0)
      newData.push(item)
      newData.push(0)
    })
    return newData;
  }

  lineUpdate(data) {
    const newData = [];
    data.forEach((item, index) => {
      newData.push(item)
      newData.push(item)
      newData.push(item)
    })
    return newData;
  }

  findIndex(data) {
    const newData = [];
    data.forEach((item, index) => {
      if (!item.includes('-B-') && !item.includes('-A-')) {
        newData.push(index)
      }
    })
    return newData;
  }
}
