import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  FooterModal,
  HeaderModal,
} from "../../../../../utils/models/common.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { NumberInputComponent } from "../../inputs/number-input/number-input.component";

@Component({
  selector: "modal-induxtry",
  templateUrl: "./modal-induxtry.component.html",
  styleUrls: ["./modal-induxtry.component.scss"],
})
export class ModalInduxtryComponent implements OnInit {
  @ViewChildren("numberInputViewChild")
  numberInputViewChild: QueryList<NumberInputComponent>;

  @Output() buttonEvent = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<any>();

  height;

  //Header
  header: HeaderModal;
  //Footer
  footer: FooterModal;

  forms: any | [] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalInduxtryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder
  ) {}

  results: any;

  ngOnInit() {
    //Header
    this.header = this.data?.header;
    //Footer
    this.footer = this.data?.footer;
    this.height = this.data?.height;
    this.forms = this.data?.forms;
  }

  genericButton(event, item) {
    event.push(this.data);
    this.buttonEvent.emit([...event, item]);
  }

  close() {
    this.dialogRef.close();
  }

  onChange(event, type?, index?) {
    this.changeEvent.emit(this.forms);
    if (this.data.customFunction) {
      this.changeUnit(event);
    }
  }

  typedValueEvent(event) {
    const allNumbersInput = this.forms.filter(
      (element: any) => element.type === "input-number" && element?.twoDecimals
    );
    allNumbersInput.forEach((element, index) => {
      this.formatTwoDecimalsInputValue(index);
    });
  }

  formatTwoDecimalsInputValue(index) {
    if (this.forms[1]?.value?.value === "UN") {
      const element: NumberInputComponent =
        this.numberInputViewChild.toArray()[0];
      const stringfiedValue = `${element.numberInput.value}`;
      if (stringfiedValue.includes(".")) {
        const newValue = stringfiedValue.replace(".", "");
        element.writeValue(Number(newValue));
      }
    }

    const element: NumberInputComponent =
      this.numberInputViewChild.toArray()[index];
    const stringfdiedValue = `${element.numberInput.value}`;
    if (
      stringfdiedValue.includes(".") &&
      stringfdiedValue.split(".")[1].length > 2
    ) {
      const validValue = `${stringfdiedValue.split(".")[0]}.${
        stringfdiedValue.split(".")[1][0]
      }${stringfdiedValue.split(".")[1][1]}`;
      element.writeValue(Number(validValue));
      element.InputViewChild.nativeElement["value"] = `${validValue}`;
    }
  }

  changeUnit(event) {
    if (event?.option === "UN") {
      const element: NumberInputComponent =
        this.numberInputViewChild.toArray()[0];
      const stringfdiedValue = `${element.numberInput.value}`;
      if (stringfdiedValue.includes(".")) {
        element.writeValue(Number(stringfdiedValue.split(".")[0]));
        element.InputViewChild.nativeElement["value"] = `${
          stringfdiedValue.split(".")[0]
        }`;
      }
    }
  }
}
