import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  
  isAuthenticated(): Promise <boolean> {
    return new Promise((resolve) => {
      const user = this.getAuthorizationToken();
      if (user) {
        resolve(true);
      }
      resolve(false);
    });
  }

  saveLocalStorage(state): Promise<any> {
    const user = JSON.stringify(state) as any
    return new Promise( (resolve) => {
      localStorage.setItem('login', JSON.parse(user).token);
      localStorage.setItem('profile', user);
      localStorage.setItem('id', JSON.parse(user).user.id);
      localStorage.setItem('name', JSON.parse(user).user.name);
      localStorage.setItem('user', JSON.parse(user).user.role);
      localStorage.setItem('type', JSON.parse(user).user.type);
      // localStorage.setItem('business', JSON.parse(user).user.business);
      resolve(true);
    });
  }

  removeLocalStorage(): Promise<any> {
    return new Promise<any>((resolve) => {
      sessionStorage.clear();
      localStorage.clear();

       // Unregister service workers
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }
      resolve(true);
    });
  }

  getAuthorizationToken() {
    return localStorage.getItem('login');
  }

}
