import moment from "moment";
import {
  dynamicValidLenghtSort,
  dynamicValidObjectTypeSort,
} from "./globalFunctions";
import { isArray } from "lodash";
import { DatePipe, DecimalPipe } from "@angular/common";
import { Sort } from "./sort";

const datePipe = new DatePipe("pt-BR");
const decimalPipe = new DecimalPipe("pt-BR");

export const wagonNecessityTotals = {
  expected: [0],
  loadedBudget: [0, 0],
  emptyBudget: [0, 0],
  prog: [0],
  loaded: [0, 0],
  empty: [0, 0],
};
export function sortFunctionData(context, a, b): number {
  let lenghtSort = 0;
  const typeData = typeof a[context._dynamicTable.requestParams.sort];

  if (typeData === "undefined") {
    lenghtSort =
      a[context._dynamicTable.requestParams.sort] === undefined &&
      b[context._dynamicTable.requestParams.sort] !== undefined
        ? 1
        : -1;
  } else if (typeData === "object") {
    const strA = a[context._dynamicTable.requestParams.sort]
      .toString()
      .toLowerCase();
    const strB = b[context._dynamicTable.requestParams.sort]
      .toString()
      .toLowerCase();
    lenghtSort = dynamicValidObjectTypeSort(strA, strB);
  } else if (
    context.validAllDate(
      a[context._dynamicTable.requestParams.sort],
      b[context._dynamicTable.requestParams.sort]
    )
  ) {
    const dates = context.attributeDateSort(
      a[context._dynamicTable.requestParams.sort],
      b[context._dynamicTable.requestParams.sort]
    );
    lenghtSort = dynamicValidLenghtSort(dates.firstDate, dates.secondDate);
  } else if (!isNaN(parseFloat(a[context._dynamicTable.requestParams.sort]))) {
    const auxA = parseFloat(a[context._dynamicTable.requestParams.sort]);
    const auxB = parseFloat(b[context._dynamicTable.requestParams.sort]);
    lenghtSort = dynamicValidLenghtSort(auxA, auxB);
  } else if (typeData === "number") {
    lenghtSort = dynamicValidLenghtSort(
      a[context._dynamicTable.requestParams.sort],
      b[context._dynamicTable.requestParams.sort]
    );
  } else if (typeData === "string") {
    const strA = a[context._dynamicTable.requestParams.sort]
      .toString()
      .toLowerCase();
    const strB = b[context._dynamicTable.requestParams.sort]
      .toString()
      .toLowerCase();

    lenghtSort = dynamicValidLenghtSort(strA, strB);
  }

  return lenghtSort;
}
export function updateFilterElements(context, key, element, tipo): void {
  if (element === null) {
    context.filterElement(key, element, " ");
  } else if (tipo === "number") {
    context.filterElement(
      key,
      element,
      context.decimalPipe.transform(element, "1.0-2")
    );
  } else if (tipo === "text" || tipo === "array") {
    context.filterElement(key, element, element);
  } else if (tipo === "date") {
    context.filterElement(
      key,
      element,
      context.datePipe.transform(element, "dd/MM/yyyy")
    );
  } else if (tipo === "date-time") {
    context.filterElement(
      key,
      element,
      context.datePipe.transform(element, "dd/MM/yyyy 'às' HH:mm")
    );
  } else if (tipo === "bool") {
    context.filterElement(key, element, context.elementBoolValid(element));
  } else if (tipo === "object") {
    context.filterElement(key, element, element);
  }
}
/**
 * @param filterUpdate Booleano que irá atualizar os filtros da tabela
 * Recarrega a tabela
 */
export function reloadTable(context, filterUpdate: boolean) {
  if (filterUpdate) {
    context.updateFilter = true;
  } else {
    context.updateFilter = false;
  }
}

export function changePage(context, event): void {
  context._dynamicTable.requestParams.page = event;
  context.loadTable();
}

export function reSelectFilter(context): Promise<any> {
  return new Promise((resolve) => {
    const selectedFilter = context?.filtersSelect || context?.filterSelected;
    if (Object.keys(selectedFilter).length > 0) {
      Object.keys(selectedFilter).forEach((column) => {
        if (
          typeof selectedFilter[column] === "string" &&
          isArray(JSON.parse(selectedFilter[column]))
        ) {
          selectedFilter[column] = JSON.parse(selectedFilter[column]);
        }

        selectedFilter[column].forEach((oldFilter, idx) => {
          const allValueFiltered = context.filterOptions2[column].map(
            (e) => e.value
          );

          if (Array.isArray(oldFilter.value)) {
            oldFilter.value.forEach((filter, idx) => {
              if (
                !allValueFiltered.find(
                  (valueFiltered) => valueFiltered.toString() === filter
                )
              ) {
                selectedFilter[column].splice(idx, 1);
              }
            });
          } else if (!allValueFiltered.some((e) => e === oldFilter.value)) {
            selectedFilter[column].splice(idx, 1);
          }
        });

        if (selectedFilter[column].length > 0) {
          const table = context.filterTable.find(
            (filter) => filter.column.attribute === column
          );
          selectedFilter[column]?.forEach((filter) => {
            table.multiSelect.multiSelect.options.forEach((o) => {
              if (o.value?.value?.toString() === filter?.value?.toString()) {
                o.select();
              }
            });
          });
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  });
}

export function attributeDateSort(context, firstDate, secondDate): Object {
  let dateA;
  let dateB;
  if (context.validDateSort(firstDate, context.dateFormat)) {
    dateA = moment(firstDate, context.dateFormat, true);
    dateB = moment(secondDate, context.dateFormat, true);
  } else {
    dateA = moment(firstDate, context.dateHourFormat, true);
    dateB = moment(secondDate, context.dateHourFormat, true);
  }

  return { firstDate: dateA, secondDate: dateB };
}

export function uncheckAll(context) {
  context.checked = [];
  context.ords = [];
  context.checks = [];
}

export function isSpliced(context, att) {
  const index = context.attributes.findIndex(
    (item) => item && item.param === att
  );

  if (index > -1) {
    context.attributes.splice(index, 1);
  }
}

export function decimalFilter(event: any) {
  const reg = /^\d*(?:[.,]\d{0,2})?$/;
  const input = event.target.value + String.fromCharCode(event.charCode);
  if (!reg.test(input)) {
    event.preventDefault();
  }
}

/**
 * @param row Seleciona a linha da tabela, normalmente
 * usado como 'e', através do índice da linha
 * @param col Seleciona a coluna da tabela, normalmente
 * usado como 'index', através do índice da coluna
 * @returns Seleciona o elemento HTML da tabela a partir
 * dos parameotros fornecido e aplica a função de .focus()
 * no elemento
 */
export function setRowCol(row, col) {
  let input = document.getElementById(`[${row + 1}][${col}]`);
  if (!input) {
    input = document.getElementById(`[${0}][${col}]`);
  }
  if (input) {
    input.focus();
  }
}

export function calculateAverage(context, att: string): number {
  if (context.data.length > 0) {
    return (
      context.data.reduce((acc, el) => {
        acc += parseInt(el[att], 10);
        return acc;
      }, 0) / context.data.length
    );
  }
  return 0;
}

export function handleAverage(context, type: string): number {
  let dataLength = context.data.length;

  if (context.data.length > 0) {
    return Math.floor(
      Math.round(
        context.data
          .map((v) => {
            return v[type];
          })
          .reduce((acc, value) => {
            if (value === "-") {
              dataLength -= 1;
              return Number(acc);
            } else {
              return (
                Number(acc) +
                Number(
                  type === "time_queue" ||
                    type === "total_time" ||
                    type === "correspondence_time"
                    ? getTotalMinutes(value)
                    : value
                )
              );
            }
          }, 0)
      ) / dataLength
    );
  }
  return 0;
}

/**
 * @param totalMinutes Número que representa os minutos que serão
 * convertidos em horas
 * @returns Uma string que indica o tempo em horas e minutos com base
 * no parãmetro fornecido. Ex: 60 -> 01:00
 */
export function getHours(totalMinutes) {
  const minutesInAnHour = 60;
  const hours = !isNaN(totalMinutes / minutesInAnHour)
    ? Math.floor(totalMinutes / minutesInAnHour).toLocaleString("pt-BR", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    : "00";
  const minutes = !isNaN(totalMinutes % minutesInAnHour)
    ? (totalMinutes % minutesInAnHour).toLocaleString("pt-BR", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    : "00";

  return `${hours}:${minutes}`;
}

/**
 * @param time String no formato de data e hora que será convertida
 * em minutos totais. Ex: '02:30'
 * @returns String que indica o número em minutos do parâmetro
 * passado. Ex: 150
 */
function getTotalMinutes(time: string) {
  const minutesInAnHour = 60;
  const hours = parseInt(time.split(":")[0], 10);
  const minutes = parseInt(time.split(":")[1], 10);
  return hours * minutesInAnHour + minutes;
}

export function resizeTable(scope, event) {
  scope.width = event.target.innerWidth;
  scope.innerHeight = window.innerHeight;
  scope.reloadSticky();
}

// Clean Table Data
export function cleanTableData(scope) {
  scope?.tabled?._noDataRowOutlet.viewContainer.clear();
  scope?.tabled?._rowOutlet.viewContainer.clear();
  scope?.tabled?._headerRowOutlet.viewContainer.clear();
  scope?.tabled?._footerRowOutlet.viewContainer.clear();

  deleteReferenceObjects(scope);

  scope?._unsubscribe$.next();
  scope?._unsubscribe$.complete();
}

function deleteReferenceObjects(scope) {
  delete scope.data;
  delete scope.originalData;
  delete scope.attributes;
  delete scope.filterOptions;
  delete scope.filterData;
  delete scope.filterSelected;
}

// onInit Commum Tables
export function onInit(scope) {
  scope.width = window.innerWidth;
  scope.dateScenario = new Date(sessionStorage.getItem("scenarioDataRef"));
  removeReferenceDynamic(scope);
  checkInitialSort(scope);
  if (scope.localData || scope._dynamicTable.endpoint === "") {
    reloadLocalTable(scope, scope.localData ? scope.localData : []);
  } else {
    scope.loadTable();
  }
}

export function reloadLocalTable(scope, event?) {
  scope.startLoading();
  scope.localData = event;
  scope.data = scope.localData;
  removeReferenceDataToFilter(scope);
  scope.cancelLoading();
}

function checkInitialSort(scope) {
  if (scope._dynamicTable?.requestParams?.order === "") {
    scope._dynamicTable.requestParams.order = "asc";
  }
}

export function removeReferenceDataToFilter(context) {
  if (context.data) {
    context.originalData = JSON.parse(JSON.stringify(context.data));
  }
}

export function removeReferenceDynamic(scope) {
  if (scope.dynamicTable) {
    scope._dynamicTable = JSON.parse(JSON.stringify(scope.dynamicTable));
  }
}

// FILTRO
export async function filterOptionConfiguration(
  context,
  column,
  tableConfig,
  useValueArray = false,
  typeTable = undefined
) {
  const attributeFilter = column.filterAttribute
    ? column.filterAttribute
    : column.attribute;
  const attribute = column.attribute;

  if (Object.keys(context.filterSelected).length <= 0) {
    removeReferenceDataToFilter(context);
  }

  context.filterOptions[attribute] = [];
  context.originalData.map((d) => {
    let element = d[attributeFilter];
    if (useValueArray) {
      element = d[attributeFilter].value;
    }

    if (element <= 0) {
      return configureStyleDataFilter(
        context,
        attribute,
        element,
        tableConfig,
        column?.labelShow
      );
    }
    return configureStyleDataFilter(
      context,
      attribute,
      element,
      tableConfig,
      column?.labelShow
    );
  });

  if (typeTable === "visualization") {
    context.configureSpecificTables(attribute);
  }

  context.filterOptions[attribute] = removeDuplicatedValues(context, attribute);
  if (Object.keys(context.filterSelected).length > 0) {
    setTimeout(() => {
      reFilterInternal(context);
    }, 300);
  }
}

function reFilterInternal(context) {
  const options = context.filterOptions;
  const filterSelected = context.filterSelected;

  removeSelected(options, filterSelected);

  Object.keys(options).forEach((column, index) => {
    const filterTable = context.filterTable.find(
      (filter) => filter.column.attribute === column
    );

    filterSelected[column]?.forEach((filter) => {
      filterTable.multiSelect.multiSelect.options.forEach((matOption) => {
        if (matOption.value?.value === filter?.value) {
          matOption.select();
        }
      });
    });
  });
}

function removeSelected(options, selected) {
  Object.keys(selected).forEach((key, index) => {
    if (options[key]) {
      const mapedSelected = selected[key].map((e) => e.friendly);
      const mapedOptions = options[key].map((e) => e.friendly);
      mapedOptions.forEach((value, indexMaped) => {
        if (value !== mapedSelected[indexMaped]) {
          selected[key].splice(indexMaped, 1);
        }
      });
    } else {
      selected.splice(index, 1);
    }
  });
}

function configureStyleDataFilter(
  context,
  attribute,
  element,
  tableConfig,
  labelShow = ""
) {
  const typeElement = tableConfig.find((e) => e.attribute === attribute)?.type;
  if (typeElement === "date") {
    return filterElement(
      context,
      attribute,
      element,
      this.datePipe.transform(element, "dd/MM/yyyy")
    );
  } else if (typeElement === "date-time") {
    return filterElement(
      context,
      attribute,
      element,
      datePipe.transform(element, "dd/MM/yyyy - HH:mm")
    );
  } else if (typeElement === "period") {
    return filterElement(
      context,
      attribute,
      element,
      `De ${datePipe.transform(
        element.startDate,
        "dd/MM/yyyy"
      )} até ${datePipe.transform(element.endDate, "dd/MM/yyyy")}`
    );
  } else if (typeElement === "boolean") {
    return filterElement(
      context,
      attribute,
      element,
      elementBoolValid(element)
    );
  } else if (typeElement === "float") {
    return filterElement(
      context,
      attribute,
      element,
      transformElement(context, element, "1.2")
    );
  } else if (typeElement === "number") {
    return filterElement(
      context,
      attribute,
      element,
      transformElement(context, element, "1.0-2")
    );
  } else if (typeElement === "object") {
    return filterElement(
      context,
      attribute,
      element,
      labelShow && element[labelShow]
        ? element[labelShow]
        : element
        ? element
        : "-"
    );
  } else if (typeElement === "array") {
    return filterElement(
      context,
      attribute,
      element,
      getArrayElements(element, labelShow)
    );
  }else if (typeElement === 'text'){
    const filtered = context.filterOptions[attribute].push({
      value: element,
      labelShow: element,
      label: element,
      friendly: element,
    })
   return context.filterOptions[attribute];
  }
  return filterElement(context, attribute, element, element ? element : "-");
}

function elementBoolValid(element) {
  return element ? "Sim" : "Não";
}

function transformElement(context, element: any, pipeType: string): any {
  if (Array.isArray(element)) {
    return getNumberInArray(context, element);
  } else {
    return decimalPipe.transform(element, pipeType);
  }
}

function getArrayElements(element, labelShow) {
  if (labelShow) {
    return element.map((obj) => obj[labelShow]).join(", ");
  }
  return element.map((obj) => obj).join(", ");
}

function getNumberInArray(context, element) {
  return element.reduce((acc, numero, index, array) => {
    if (index % 2 === 0) {
      // Se o índice for par, adicionamos um hífen e um espaço
      return `${acc}${decimalPipe.transform(numero, "1.2")}${" - "}`;
    } else {
      // Se o índice for ímpar, apenas adicionamos o número e uma vírgula
      return `${acc}${decimalPipe.transform(numero, "1.2")}${
        index === array.length - 1 ? "" : " - "
      }`;
    }
  }, "");
}

function removeDuplicatedValues(context, atribuite) {
  const uniqueValues = new Set();
  return context.filterOptions[atribuite].filter((item) => {
    const value = JSON.stringify(item.value);
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      return true;
    }
    return false;
  });
}

function filterElement(context, key, valueElement, friendlyElement) {
  context.filterOptions[key].push({
    value: valueElement,
    friendly: friendlyElement,
  });
}

export function handleFilter(
  event,
  columnFilter,
  context,
  useValueArray = false,
  cleanButton = false
) {
  let filterDoned = [];
  addSelectedFilter(event, columnFilter, context);
  Object.keys(context.filterSelected)
    .filter((key) => context.filterSelected[key].length > 0)
    .forEach((key, index) => {
      filterDoned = getDataFiltered(
        key,
        filterDoned.length > 0 || index !== 0
          ? filterDoned
          : context.originalData,
        context,
        useValueArray,
        cleanButton
      );
    });
  context.data = filterDoned;
  if (context.data.length <= 0 && !hasFilterSelected(context)) {
    context.data = context.originalData;
  }
}

function addSelectedFilter(event, column, context) {
  context.filterSelected[column] = [...new Set(event[column])];
}

function getDataFiltered(
  key,
  dataCanFilter,
  context,
  useValueArray,
  cleanButton = false
) {
  const allFilteredData = [];
  for (const filter of context.filterSelected[key]) {
    if (typeof filter.value === "number") {
      filter.value = `${filter.value}`;
    }

    if (typeof filter.value === "string") {
      allFilteredData.push(
        ...dataCanFilter.filter((oriData) =>
          useValueArray
            ? oriData[key].value?.toString() === filter?.value
            : oriData[key]?.toString() === filter?.value
        )
      );
    } else {
      allFilteredData.push(
        ...filterDataObject(
          dataCanFilter,
          key,
          filter,
          useValueArray,
          cleanButton
        )
      );
    }
  }

  return allFilteredData;
}

function filterDataObject(
  dataCanFilter,
  key,

  filter,
  useValueArray,
  cleanButton = false
) {
  const output = dataCanFilter.filter((oriData) => {
    if (oriData[key]?.labelShow && isArray(oriData[key]?.value)) {
      const friendlyJoined = oriData[key].value
        .map((obj) => obj[oriData[key].labelShow])
        .join(", ");
      if (friendlyJoined === filter.friendly) {
        return oriData;
      }
    } else if (oriData[key]?.labelShow) {
      if (
        oriData[key].value[oriData[key].labelShow] ===
        filter.value[oriData[key].labelShow]
      ) {
        return oriData;
      }
    } else {
      if (oriData[key] === filter?.value) {
        return true;
      } else if (oriData[key][0] === filter?.value[0]) {
        return true;
      } else {
        return false;
      }
    }
  });
  return output;
}

function hasFilterSelected(context) {
  let hasFilter = false;
  Object.keys(context.filterSelected).forEach((key) => {
    if (context.filterSelected[key].length > 0) {
      hasFilter = true;
    }
  });

  return hasFilter;
}

// Sort Internal
export function handleSort(
  scope,
  tableConfig,
  att: string,
  isInternalTable = false
): void {
  const sort = new Sort();

  const displayedColumn = (
    tableConfig.displayedColumns || tableConfig.columns
  ).find((column) => column.attribute === att);
  if (
    tableConfig.requestParams.order === "" ||
    tableConfig.requestParams.order === "desc"
  ) {
    tableConfig.requestParams.sort = att;
    tableConfig.requestParams.order = "asc";
  } else {
    tableConfig.requestParams.order = "desc";
    tableConfig.requestParams.sort = att;
  }
  scope.data.sort(
    sort.startSort(
      att,
      tableConfig.requestParams.order,
      displayedColumn.type,
      isInternalTable
    )
  );
  scope.data = [...scope.data];
}
