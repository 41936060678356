import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * The infinite Scroll Validation With icon directive can be used
 * in `mat-form-field` tags whith `infinite-scroll` or `select` child,
 * to do validation with x icon, cancel icon.
 *  All the code below is executed after the html is rendered in Angular.
 * When modifying the styling directive do the tests with styling after the html is rendered.
 * @public
 */

@Directive({
  selector: '[infiniteScrollValidationWithXicon]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InfiniteScrollValidationWithIconXDirective,
      multi: true,
    },
  ],
})
export class InfiniteScrollValidationWithIconXDirective implements OnInit, OnDestroy {

  @Input('infiniteScrollValidationWithXicon') infiniteScrollValidationWithXicon

  private readonly _unsubscribeAll$: Subject<any> = new Subject();

  gradientString = '<div class="gradient-infinite-scroll"></div>';

  iconString = `
    <mat-icon 
      _ngcontent-xca-c359=""
      role="img"
      matsuffix=""
      style="color: #E24445; background-color:white; font-size:20px !important; position: absolute; bottom: 4px; left: -27px;"
      class=" notranslate icon-error material-icons mat-icon-no-color ng-tns-c149-52 ng-star-inserted"
      aria-hidden="true"
      data-mat-icon-type="font">
        highlight_off
    </mat-icon>
  `;

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(MAT_FORM_FIELD) private readonly mat: MatFormField,
    public renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.mat._control?.stateChanges) {
      this.mat._control?.stateChanges.pipe(takeUntil(this._unsubscribeAll$)).subscribe(() => {
        setTimeout(() => {
          this.verificarIcone();
        }, 100);
      })
    }


  }

  verificarIcone(): void {
    if (this.infiniteScrollValidationWithXicon.invalid) {
      this.exibirIcone();
    } else {
      this.removerIcone();
    }
  }

  exibirIcone(): void {
    const wrapper = this.elementRef.nativeElement.querySelector('.mat-form-field-wrapper');
    const flex = wrapper.querySelector('.mat-form-field-flex');
    if (this.infiniteScrollValidationWithXicon.invalid === 'Aviso') {
      const children = flex.children[1];
      this.renderer.setStyle(children, 'color', '#FFCD48');
      const mensagemErro = wrapper.querySelector('.mat-error');
      if(mensagemErro){
        this.renderer.setStyle(mensagemErro, 'color', '#FFCD48');
      }

    }
    const iconStringErroVermelho = `
      <div style="position: relative; top: 12px !important;color:#E24445 !important;" class="mat-form-field-suffix ng-tns-c149-52 ng-star-inserted">
        ${this.gradientString}
        ${this.iconString}
      </div>
    `;

    flex.insertAdjacentHTML('beforeend', iconStringErroVermelho );
  }

  removerIcone(): void {
    this.elementRef.nativeElement.querySelectorAll('mat-icon').forEach((e) => e.parentNode.removeChild(e));
    this.elementRef.nativeElement.querySelectorAll('.gradient-infinite-scroll').forEach((e) => e.parentNode.removeChild(e));
    const wrapper = this.elementRef.nativeElement.querySelector('.mat-form-field-wrapper');
    wrapper.parentNode.classList.remove('mat-focused');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }
}
