import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../../service/auth/auth.service";
import {
  AvatarInterface,
  statusAvatar,
} from "../../../../../utils/models/miscelanea.interface";
import { GlobalDataService } from "src/app/shared/service/globalDataService";

@Component({
  selector: "app-sidenav-user",
  templateUrl: "./sidenav-user.component.html",
  styleUrls: ["./sidenav-user.component.scss"],
})
export class SidenavUserComponent implements OnInit, OnChanges {
  usercontrol = false;
  myCollapse;
  myCollapsedUserPanel;
  _collapsedPanel = false;
  menuStatus = false;

  @Input() collapse;
  @Input() link;
  @Output() collapsedUserPanel = new EventEmitter();

  @Output() linkClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  userName: string;
  picture: string;
  status: statusAvatar;
  profileUser: string;
  initialName: string[];
  statusPanel = false;
  avatarInterface: AvatarInterface;

  constructor(
    private readonly route: Router,
    private readonly auth: AuthService,
    public globalService: GlobalDataService
  ) {}

  ngOnInit() {
    this.myCollapse = true;
    this.myCollapsedUserPanel = false;
    this.userName = localStorage.getItem("name");
    this.picture = localStorage.getItem("picture");
    this.status = localStorage.getItem("status") as statusAvatar;
    this.profileUser = localStorage.getItem("business");

    this.adjustUser(this.profileUser);

    // this.initialName = this.userName?.split(' ')
    this.avatarInterface = {
      name: this.userName,
      status: this.status,
      picture: this.picture,
      // customColor: {background:'#FDF1D8',content:'#5F4407'}
    };
  }

  ngOnChanges(change) {
    if (change.collapse) {
      this.myCollapse = this.collapse;
    }
  }

  sendCollapsedUserPanel() {
    this.collapsedUserPanel.emit(true);
  }

  handleExit() {
    this.auth.removeLocalStorage().then(() => {
      this.route.navigate(["/autenticacao/login"]);
      this.globalService.setterToogleChange(false)
    });
  }

  controlPainel() {
    this._collapsedPanel = !this._collapsedPanel;
  }

  handleClick(event: boolean): void {
    this.linkClose.emit(event);
  }

  menuOpened(): void {
    this.menuStatus = true;
  }

  menuClosed(): void {
    this.menuStatus = false;
  }

  adjustUser(value) {
    if (value?.length > 16) {
      const palavras = value.split(" ");

      if (palavras?.length > 1) {
        palavras[0] = palavras[0].substring(0, 2) + ".  ";
      }

      const resultado = palavras.join(" ");

      this.profileUser = resultado;
    } else {
      this.profileUser = value;
    }
  }
}
