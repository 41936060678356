import {  Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BannerInterface } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() banner: BannerInterface;

  @Output() handleClick: EventEmitter<string> = new EventEmitter();
  @Output() onClose: EventEmitter<string> = new EventEmitter();

  icon = {
    error: 'circle_cancel',
    info: 'circle_information',
    neutral: 'circle_alert',
    warning: 'circle_warning',
    success: 'circle_success',
    question: 'circle_question'
  }

  title = {
    error: 'Error',
    info: 'Info',
    neutral: 'Neutral alert',
    warning: 'Critical alert',
    success: 'Success',
    question: 'Help'
  }

  constructor(private readonly element: ElementRef) { }


  ngOnInit(): void {
    this.getIcon(this.banner.status)
  }



  getIcon(status:string) {
    return this.icon[status]
  }

  getTitle(status:string) {
    return this.title[status]
  }

  clickButton(event){
    this.handleClick.emit(event)
  }

  close(): void {

    this.element.nativeElement.remove();
    this.onClose.emit(this.banner.key);
  }

}
