import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { SelectInterface } from "../../../../../../utils/models/table.interface";
import {
  RequestAttribute,
  RequestParams,
} from "./../../../../../../utils/models/http.interface";
import { HttpService as HttpServiceParams } from "./../../../../../service/http/http.service";
import { map } from "rxjs/operators";

// Interface

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private httpServiceParams: HttpServiceParams) {}

  genericGetListTable(
    endpoint: string,
    requestParamsDynamic: RequestParams,
    attrDynamic?: RequestAttribute[],
    filterAttributeDynamic?: RequestAttribute[]
  ): Observable<any> {
    return this.httpServiceParams.genericGetListTable(
      endpoint,
      requestParamsDynamic,
      attrDynamic,
      filterAttributeDynamic
    );
  }

  genericGetSelect(
    selectInterface?: SelectInterface,
    actualLine?: any
  ): Observable<any> {
    if (selectInterface) {
      if (selectInterface.mockData !== undefined) {
        return of(selectInterface.mockData);
      } else {
        const attType = selectInterface.attributes.map((att) => {
          if (att.value.includes(`%{salesChannel}`)) {
            att.value = actualLine[`salesChannel`].toLowerCase();
          }
          if (att.value.includes(`%{business}`)) {
            att.value = localStorage.getItem('business');
          }
          return att;
        });
        return this.httpServiceParams.genericGetParams(
          selectInterface.endpoint,
          attType
        );
      }
    } else {
      return of([]);
    }
  }

  genericGet(endpoint) {
    return this.httpServiceParams
      .genericGet<any>(endpoint)
      .pipe(map((response) => response));
  }

  genericPutTable(endpoint, body){
    return this.httpServiceParams.genericPut(endpoint, body).pipe((map((response)=> response)))
  }
}
