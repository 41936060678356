import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'swal',
  templateUrl: './swal.component.html',
  styleUrls: ['./swal.component.scss']
})
export class SwalComponent implements OnInit{

  @Output() handleClickEvent: EventEmitter<any> = new EventEmitter<any>();
  list: string[];
  typeLogin: string;
  images: string[] = [
    '../../../../../../assets/icons/logos/logo_diaco.svg',
    '../../../../../../assets/icons/logos/logo_cyrgo.svg',
    // Adicione mais imagens conforme necessário
  ];

  constructor(
    public dialogRef: MatDialogRef<SwalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly cdr: ChangeDetectorRef
  ) { }
  hour: Date
  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }

  ngOnInit(): void {
    this.updateHour();
    if(this.data?.type === 'success'){
      this.data['type'] = 'warning-sucess';
    } else if(this.data?.type === 'error') {
      this.data['type'] = 'warning-error';
    } else if(this.data?.type === 'alert') {
      this.data['type'] = 'warning-alert';
    } else if(this.data?.type === 'info') {
      this.data['type'] = 'warning-information';
    } else if(this.data?.type === 'neutral') {
      this.data['type'] = 'warning-neutral';
    }

    this.cdr.detectChanges();

    if(this.data.listItems){
      this.list = Object.keys(this.data.listItems)
      this.list = this.list.filter((el)=> el !== "role")
    }
  }

  private updateHour(): void {
    this.hour = new Date();
  }

  handleEvent(event) {
    if(this.data.login){
      const res = {
        event,
        business: this.typeLogin
      }
      this.handleClickEvent.emit(res)
    }else{
      this.handleClickEvent.emit(event)
    }
  }

  subTitleIsObject() {
    if(this.data && typeof this.data.pergunta === 'string') {
      return true
    }

    return false;
  }

  formatarLinhas(array, erros?): string {

    if(erros){
      if (array.length === 1) {
          return `Línea ${array[0]}`;
      }
      const numerosStr = array.map(num => num.toString());
      const ultimoNumero = numerosStr.pop();
      const resultado = numerosStr.join(', ') + ' y ' + ultimoNumero;
      return `Líneas ${resultado}`;
    }else{
  
          if(array === 'skuCode'){
            return `SKU: ${this.data.listItems[array]}`
          }
          if(array === 'name'){
            return `Nombre del producto: ${this.data.listItems[array]}`
          }
          if(array === 'standardUnit'){
            return `Unidad Estándar: ${this.data.listItems[array]}`
          }
          if(array === 'kgPerUnit'){
            return `Peso de la unidad: ${this.data.listItems[array]}`
          }else{
            return
          }
        
      
    }
}
}
