import { MatDatepicker } from "@angular/material/datepicker";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { CalendarPickerComponent } from "../calendar-picker.component";

import moment, { Moment } from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  forwardRef,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { HeaderComponentComponent } from "../header-component/header-component.component";

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const datePickerClass = {
  MONTH: "calendar-picker-month",
  YEAR: "calendar-picker-year",
  DAY: "calendar-picker",
  PERIOD: "calendar-picker-period",
};

@Component({
  selector: "calendar-period",
  templateUrl: "./calendar-period.component.html",
  styleUrls: ["./calendar-period.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarPeriodComponent),
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "pt-br" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class CalendarPeriodComponent
  implements ControlValueAccessor, AfterViewInit
{
  public mask = {
    guide: true,
    showMask: true,
    mask: ["De ", /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  };
  @Input() label = "";
  @Input() customFormFieldClass = "";
  @Output() onChangeEnd: EventEmitter<any> = new EventEmitter();
  @Output() onChangeStart: EventEmitter<any> = new EventEmitter();
  headerPicker = HeaderComponentComponent;
  @Input() required = false;

  _max: Moment;
  @Input()
  get max(): Date {
    return this._max ? this._max.toDate() : undefined;
  }
  set max(max: Date) {
    if (max) {
      const momentDate = moment(max, moment.ISO_8601);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _min: Moment;
  @Input()
  get min(): Date {
    return this._min ? this._min.toDate() : undefined;
  }
  set min(min: Date) {
    if (min) {
      const momentDate = moment(min, moment.ISO_8601);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _mode: "PERIODO";
  @Input()
  get mode(): "PERIODO" {
    return this._mode;
  }
  set mode(mode: "PERIODO") {
    this._mode = mode;
    this._setupFilter();
  }

  @Input() touchUi = false;
  @Input("formGroup") formGroup;

  _customFilter: (d: Moment) => boolean;

  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;

  _panelClass = datePickerClass.PERIOD;
  _typeDate = "start";

  // Function to call when the date changes.
  onChange = (date: Date) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  /** send the focus away from the input so it doesn't open again */
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  constructor(private readonly parent: CalendarPickerComponent) {}

  ngAfterViewInit() {
    this._takeFocusAway = this.parent._takeFocusAway;
  }

  _writeValue(date: Date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      if (this._typeDate === "start") {
        this.formGroup
          .get("startDate")
          .setValue(moment(date), { emitEvent: false });
      } else {
        this.formGroup
          .get("endDate")
          .setValue(moment(date), { emitEvent: false });
      }
    }
  }

  writeValue(date: Date): void {
    this._writeValue(date);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled
      ? this.formGroup.get("startDate").disable()
      : this.formGroup.get("startDate").enable();
  }

  _dateChangeHandler(chosenDateStart, chosenDateEnd) {
		// this._typeDate = typeDate;
		// if (typeDate === "start") {
		// } else if (typeDate === "end") {
		// }
		if (!!chosenDateStart) {
			this.onChangeStart.emit(chosenDateStart);
			this.onChange(chosenDateStart);
		}
		if (!!chosenDateEnd) {
			this.onChangeEnd.emit(chosenDateEnd);
			this.onChange(chosenDateEnd);
		}
		this.onTouched();
	}

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClass(datePickerClass.PERIOD);
      datepicker.open();
      this.onTouched();
    }
  }

  private _setupFilter() {
    if (this.mode === "PERIODO") {
      this._customFilter = (d: Moment) => {
        return !d.day();
      };
    }
  }
  setPanelClass(view) {
    if (this._panelClass === "") {
      this._panelClass = datePickerClass.PERIOD;
    } else {
      this._panelClass = view;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  capitalizeMonth() {
    const htmlDivElement = document.getElementsByClassName(
      "mat-calendar-body-cell-content"
    ) as HTMLCollectionOf<HTMLElement>;
    Array.from(htmlDivElement).forEach((month) => {
      month.innerText = month.innerText.toLowerCase();
      month.innerText =
        month.innerText.charAt(0).toUpperCase() + month.innerText.slice(1);
    });
  }

  changePanelClass(event) {
    const string = "year";
    if (event === string) {
      this.setPanelClass(datePickerClass.MONTH);
      this.capitalizeMonth();
    } else if (event === "multi-year") {
      this.setPanelClass(datePickerClass.YEAR);
    } else {
      this.setPanelClass(datePickerClass.PERIOD);
    }
  }
  get edited() {
    return this.formGroup.get("startDate").value === null;
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd?: HTMLInputElement,) {
    this._dateChangeHandler(dateRangeStart.value, dateRangeEnd.value)
  }
}
