import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalDataService {
  isToogleCtive$ = new BehaviorSubject<boolean>(false);
  graphProducts$ = new Subject<any>();
  showFilter: EventEmitter<boolean> = new EventEmitter();
  getAllProducts$ = new BehaviorSubject<any[]>([]);
  downloadExcel: EventEmitter<boolean> = new EventEmitter();
  downloadSheetClick$ = new BehaviorSubject<boolean>(false);
  filterCliked$ = new BehaviorSubject<boolean>(false);

  getterIsToogleActive() {
    return this.isToogleCtive$.value;
  }

  setterToogleChange(boolean) {
    this.isToogleCtive$.next(boolean);
  }

  getterProducts(value) {
    this.graphProducts$.next(value);
  }

  setShowFilter() {
    this.filterCliked$.next(true);
    this.showFilter.emit(true);
  }

  handleDownload() {
    this.downloadExcel.emit(true);
    this.downloadSheetClick$.next(true);
  }
}
