import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Http
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Language
import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
registerLocaleData(localept, 'pt');

// Routing
import { AppRoutingModule } from './app-routing.module';

// Shared Module
import { SharedModule } from './shared/shared.module';

// Component
import { AppComponent } from './app.component';
import { AuthService } from './service/auth/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInterceptor } from './service/auth/auth.interceptor';
  
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
  ],
  exports: [
    BrowserAnimationsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS,
      useFactory: function(authService: AuthService, router: Router, dialog: MatDialog, toastr: ToastrService) {
        return new AuthInterceptor(authService, router, dialog, toastr);
      },
      multi: true,
      deps: [AuthService, Router, MatDialog, ToastrService]
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
