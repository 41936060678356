import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import {
  FooterAccordion,
  HeaderAccordion,
} from "../../../../../utils/models/common.interface";
import {
  ButtonInduxtry,
  TextInput,
} from "../../../../../utils/models/input.interface";

@Component({
  selector: "accordion-induxtry",
  templateUrl: "./accordion-induxtry.component.html",
  styleUrls: ["./accordion-induxtry.component.scss"],
})
export class AccordionInduxtryComponent implements OnInit, OnChanges {
  @ViewChild("panel") panel: MatExpansionPanel;

  @Input() header: HeaderAccordion;
  @Input() footer: FooterAccordion;
  @Input() setPanelState: boolean;
  @Input() highlight: boolean;

  @Output() buttonValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() editValueTitle: EventEmitter<HeaderAccordion> =
    new EventEmitter<HeaderAccordion>();
  @Output() getPanelState: EventEmitter<boolean> = new EventEmitter<boolean>();

  width;

  edit = false;

  statusPanel = false;

  buttonFooter: ButtonInduxtry[];
  buttonInterfaceIcon: ButtonInduxtry[];

  editTitleButton: ButtonInduxtry;

  textInput: TextInput;

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    this.width = event.target.innerWidth;
  }

  ngOnInit(): void {
    this.width = window.innerWidth;
    this.editTitleButton = {
      label: "BUTTON",
      icon: "Edit",
      showLabel: false,
      customStyle: "tertiary",
      key: "editHeader",
      tooltip: "Example",
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.panel) {
      this.panel["expanded"] = this.setPanelState;
    }
  }

  editTitle(event) {
    event[0].stopPropagation();
    this.edit = !this.edit;
    this.editTitleButton.icon = this.edit ? "save-accordion" : "Edit";
    if (!this.edit) {
      this.editValueTitle.emit(this.header);
    }
  }

  genericButton(event) {
    this.buttonValue.emit(event[1]);
  }

  onClick(event: Event): void {
    event.stopPropagation();
  }

  statePanel(value) {
    this.getPanelState.emit(value);
    this.statusPanel = value;
  }
}
