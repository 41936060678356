import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { openModalLoading } from '../../../../shared/service/utils';
import { Chart } from 'chart.js';
import { PieDataChartConfigInterface } from 'src/utils/models/pieChart';

@Component({
  selector: 'app-pizza-chart',
  templateUrl: './pizza-chart.component.html',
  styleUrls: ['./pizza-chart.component.scss']
})
export class PizzaChartComponent implements OnInit {

  @Input() chartData: PieDataChartConfigInterface

  @Input() hasChartData = false;

  printId: string

  constructor(private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.printId = this.chartData?.id + 'wrapper'
  }

  hidenOrShowData(e) {
      const chart = Chart.getChart(this.chartData?.id);
      chart.toggleDataVisibility(e?.dataSetIndex);
      chart.update();  
  }

  async picture(event) {
    const graphic = document.getElementById(this.printId);
    const textDownload = this.chartData.title + '.png'
    if (graphic) {
      const loading = openModalLoading(
        this.dialog,
        "Cargando",
        "Descargar el gráfico como imagen"
      );
      await html2canvas(graphic).then(function (canvas) {
				const image = canvas.toDataURL();
				const link = document.createElement('a');
				link.href = image;
				link.download = textDownload;
				link.dispatchEvent(new MouseEvent('click'));
			});
      loading.close();
    }
  }

}
