import { Warning } from './../../../utils/models/input.interface';
import { SelectInfiniteScrollSearchComponent } from './../components/scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component';
import { FormControl } from "@angular/forms";
import lodash from "lodash";
import moment from "moment";

export function validDateSort(date, format) {
  return moment(date, format, true).isValid();
}

export function propertyObjWarning(warning: Warning): string {
  let propertyWarning = "default";
  if (warning.sucess) {
    propertyWarning = "sucess";
  } else if (warning.error) {
    propertyWarning = "error";
  } else if (warning.info) {
    propertyWarning = "info";
  } else if (warning.alert) {
    propertyWarning = "alert";
  } else if (warning.load) {
    propertyWarning = "load";
  }
  return propertyWarning;
}

export function returnColorWarning(warning: Warning): object {
  const porpertyIcon = propertyObjWarning(warning);
  const warningColors = {
    sucess: { color: "#115F22" },
    info: { color: "#1F52AB" },
    alert: { color: "#5F4407" },
    error: { color: "#A80B00" },
    load: { color: "#102956" },
  };
  return warningColors[porpertyIcon] ? warningColors[porpertyIcon] : {};
}

export function returnWarningIcon(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  const iconsWarning = {
    sucess: "warning-sucess",
    error: "warning-error",
    info: "warning-circle-information",
    alert: "warning-circle-alert",
    load: "loading",
  };
  return iconsWarning[porpertyIcon] ? iconsWarning[porpertyIcon] : "";
}

export function returnTextWarning(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  return warning[porpertyIcon] && warning[porpertyIcon].text
    ? warning[porpertyIcon].text
    : "Warning Message";
}

export function checkKeyAndReturnYesOrNo(obj) {
  if (obj) {
    return "Sim";
  } else {
    return "Não";
  }
}

export function checkKeyAndReturnTrueOrNot(obj) {
  if (obj) {
    return "true";
  } else {
    return "false";
  }
}

export function checkLimitErrorsPort(
  form,
  minLimit,
  maxLimit,
  minLimitTax,
  maxLimitTaxValue
) {
  const fieldsToCheck = [minLimit, maxLimit, minLimitTax, maxLimitTaxValue];
  let filledFields = 0;
  for (const field of fieldsToCheck) {
    const value = form.get(field).value;
    if (value != null) {
      filledFields++;
    }
  }

  let HAS_GREATHER_THAN_100 = false;
  for (const field of fieldsToCheck) {
    if (field === minLimit || field === maxLimit) {
      const value = form.get(field).value;
      if (!isNaN(value) && +value > 100) {
        form.get(field).setErrors({ greatherThan100: true });
        HAS_GREATHER_THAN_100 = true;
      }
    }
  }
  if (HAS_GREATHER_THAN_100) {
    return;
  }

  if (filledFields === 0) {
    fieldsToCheck.forEach((field) => {
      form.get(field).setErrors(null);
    });
  } else {
    fieldsToCheck.forEach((field) => {
      (form.get(field) as FormControl).markAsTouched();
    });
    let minimumLimit = form.get(minLimit).value;
    let maximumLimit = form.get(maxLimit).value;
    if (minimumLimit == null || isNaN(minimumLimit)) {
      minimumLimit = 0;
      form.get(minLimit).setErrors({ invalid: true });
    }
    if (maximumLimit == null || isNaN(maximumLimit)) {
      maximumLimit = 0;
      form.get(maxLimit).setErrors({ invalid: true });
    }
    if (maximumLimit <= minimumLimit) {
      if (form.get(minLimit).value != null) {
        form.get(minLimit).setErrors({ invalid: true });
      }
      if (form.get(maxLimit).value != null) {
        form.get(maxLimit).setErrors({ invalid: true });
      }
    } else {
      if (form.get(minLimit).value && !isNaN(form.get(minLimit).value)) {
        form.get(minLimit).setErrors(null);
      } else {
        form.get(minLimit).setErrors({ invalid: true });
      }
      if (
        form.get(maxLimit).value != null &&
        !isNaN(form.get(maxLimit).value)
      ) {
        form.get(maxLimit).setErrors(null);
      } else {
        form.get(maxLimit).setErrors({ invalid: true });
      }
    }

    let minimumLimitTax = form.get(minLimitTax).value;
    let maxLimitTax = form.get(maxLimitTaxValue).value;
    if (minimumLimitTax === null || isNaN(minimumLimitTax)) {
      minimumLimitTax = 0;
      form.get(minLimitTax).setErrors({ invalid: true });
    }

    if (maxLimitTax === null || isNaN(maxLimitTax)) {
      maxLimitTax = 0;
      form.get(maxLimitTaxValue).setErrors({ invalid: true });
    }

    if (maxLimitTax <= minimumLimitTax) {
      if (form.get(minLimitTax).value != null) {
        form.get(minLimitTax).setErrors({ invalid: true });
      }
      if (form.get(maxLimitTaxValue).value != null) {
        form.get(maxLimitTaxValue).setErrors({ invalid: true });
      }
    } else {
      if (
        form.get(minLimitTax).value != null &&
        !isNaN(form.get(minLimitTax).value)
      ) {
        form.get(minLimitTax).setErrors(null);
      } else {
        form.get(minLimitTax).setErrors({ invalid: true });
      }
      if (
        form.get(maxLimitTaxValue).value != null &&
        !isNaN(form.get(maxLimitTaxValue).value)
      ) {
        form.get(maxLimitTaxValue).setErrors(null);
      } else {
        form.get(maxLimitTaxValue).setErrors({ invalid: true });
      }
    }
  }
}

export function dynamicTableDefaultMapRequest(
  mappedSeries,
  dynamicTable,
  data
) {
  mappedSeries.forEach((serie) => {
    dynamicTable.displayedColumns.push({
      attribute: serie,
      title: serie,
      hasInput: "text",
      type: "text",
      hasSort: false,
      hasFilter: false,
      width: "120px",
      hasMask: true,
      mask: {
        prefix: "",
        decimal: ",",
        thousands: ".",
        precision: 0,
        align: "left",
        nullable: false,
        allowNegative: false,
      },
    });
  });
  dynamicTable.displayedColumns.push({
    attribute: "$total",
    title: "Total",
    type: "text",
    hasSort: false,
    hasFilter: false,
    width: "120px",
  });
  data.rows.forEach((row) => {
    if (row.locomotive_pool) {
      row["$total"] = null;
      row.locomotives?.forEach((locomotive) => {
        row[locomotive.series] = null;
      });
    } else {
      row["$total"] = 0;
      row.locomotives?.forEach((locomotive) => {
        row[locomotive.series] = locomotive.alocation
          ? locomotive.alocation
          : 0;
        row["$total"] += row[locomotive.series];
      });
    }
  });
}

export function tableLinesInitialization(elementRef, stringfiedStyle) {
  const firstTd = elementRef.nativeElement.querySelector("td");
  const firstRow = firstTd.parentNode;
  const allRows = firstRow.parentNode.childNodes;

  if (!!allRows) {
    allRows.forEach((tr, index) => {
      tr.childNodes.forEach((td, indexColumn) => {
        if (td?.localName === "td") {
          td?.setAttribute(
            "style",
            `
            ${stringfiedStyle}
            ${td.getAttribute("style") ? td.getAttribute("style") : ""}

            `
          );
        }
      });
    });
  }
}

// export function updateOptionsLocomotives(viewChildTable, halls, fleets) {
//   viewChildTable?.selectInput
//     ?.filter(
//       (infiniteScroll: SelectInfiniteScrollSearchComponent) =>
//         infiniteScroll.groupOfields === "originCorridor" ||
//         infiniteScroll.groupOfields === "destinyCorridor"
//     )
//     .forEach((scroll: SelectInfiniteScrollSearchComponent) => {
//       scroll._options = halls;
//       scroll.internalFilter = true;
//       if (scroll.value) {
//         scroll._options = lodash.uniqBy(
//           [...scroll._options, scroll.value],
//           scroll.labelShow
//         );
//       }
//     });

//   viewChildTable?.selectInput
//     ?.filter(
//       (selectInfinite: SelectInfiniteScrollSearchComponent) =>
//         selectInfinite.groupOfields === "fleet"
//     )
//     .forEach((scroll: SelectInfiniteScrollSearchComponent) => {
//       scroll._options = fleets;
//       scroll.internalFilter = true;
//       if (scroll.value) {
//         scroll._options = lodash.uniqBy(
//           [...scroll._options, scroll.value],
//           scroll.labelShow
//         );
//       }
//       scroll.forceSortParam = "vag_no_frota";
//     });
// }

export function dynamicValidLenghtSort(a, b) {
  return a > b ? 1 : -1;
}

export function dynamicValidObjectTypeSort(objectA, objectB) {
  if (objectA === "" && objectB !== "") {
    return 1;
  } else if (objectA !== "" && objectB === "") {
    return -1;
  }
  return dynamicValidLenghtSort(objectA, objectB);
}

export function buildDynamicAttributes(
  classificationForm,
  attributes,
  isOpened,
  dynamicTable
) {
  if (!!classificationForm) {
    const idx = attributes.findIndex((value) => value.param === "cla_id");
    if (idx > -1) {
      attributes[idx].value = classificationForm;
    } else {
      attributes.push({
        param: "cla_id",
        value: classificationForm,
      });
    }
  } else {
    attributes = attributes.filter((value) => value.param !== "cla_id");
  }
  if (isOpened) {
    dynamicTable.loadTable(attributes, true);
  }
}

export function attributesScenarioBuilder(corId) {
  return [
    {
      param: "cen_status",
      value: "new",
    },
    {
      param: "cor_id",
      value: sessionStorage.getItem("corridorId"),
    },
    {
      param: "cen_nm_nome",
      value: "",
    },
  ];
}

export function updateAttributesScenarios(
  simulationScenarios,
  corridorId,
  corridorAttr,
  simulationAttr
) {
  corridorId = sessionStorage.getItem("corridorId");
  corridorAttr[0] = { param: "cor_id", value: corridorId };
  simulationAttr[1] = { param: "cor_id", value: corridorId };
  simulationScenarios[1] = { param: "cor_id", value: corridorId };
  if (corridorId === "null") {
    corridorId = null;
  }
}

export function globalCapitalizeCalendarMonth() {
  const content = document.getElementsByClassName(
    "mat-calendar-body-cell-content"
  ) as HTMLCollectionOf<HTMLElement>;
  Array.from(content).forEach((month) => {
    month.innerText = month.innerText.toLowerCase();
    month.innerText =
      month.innerText.charAt(0).toUpperCase() + month.innerText.slice(1);
  });
}

export function globalCheckErrors(conditionOne, conditionTwo, flag) {
  if (conditionOne) {
    flag = true;
    return;
  }
  if (conditionTwo) {
    flag = true;
    return;
  }
  flag = false;
}

export function globalTooltipConfig(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    const table = document.createElement("table");
    table.style.margin = "0px";
    tooltipEl = document.createElement("div");
    tooltipEl.style.position = "absolute";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.justifyContent = "center";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.display = "flex";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.background = "rgb(87, 87, 87,0.9)";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.alignItems = "center";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
}

export function globalCurrencyMaskConfig(masksInternalTable) {
  masksInternalTable.set("num_wagons", {
    prefix: "",
    decimal: ",",
    thousands: ".",
    precision: 0,
    align: "left",
    nullable: false,
    allowNegative: false,
  });
  masksInternalTable.set("maximum_product", {
    prefix: "",
    decimal: ",",
    thousands: ".",
    precision: 0,
    align: "left",
    nullable: false,
    allowNegative: false,
  });
  masksInternalTable.set("minimum_product", {
    thousands: ".",
    prefix: "",
    decimal: ",",
    precision: 0,
    align: "left",
    nullable: false,
    allowNegative: false,
  });
  masksInternalTable.set("minimum_global", {
    prefix: "",
    decimal: ",",
    thousands: ".",
    precision: 0,
    align: "left",
    nullable: false,
    allowNegative: false,
  });
}

export function globalMultiselectInterface(recorrencyOptions) {
  return {
    selectedItem: null,
    label: "Recorrência",
    placeholder: "EX: Todos os dias",
    options: JSON.parse(
      JSON.stringify(
        recorrencyOptions.map((x) => {
          x.disabled = false;
          return x;
        })
      )
    ),
    required: true,
  };
}
