import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Toast, ToastrService, ToastPackage } from "ngx-toastr";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map } from "rxjs/operators";
import { MyToastService } from "src/app/shared/service/myToast";

@Component({
  selector: "app-mytoastr",
  styleUrls: ["./mytoastr.component.scss"],
  templateUrl: "./mytoastr.component.html",
  animations: [
    trigger("flyInOut", [
      state(
        "inactive",
        style({
          opacity: 0,
        })
      ),
      transition(
        "inactive => active",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              transform: "translate3d(100%, 0, 0) skewX(-30deg)",
              opacity: 0,
            }),
            style({
              transform: "skewX(20deg)",
              opacity: 1,
            }),
            style({
              transform: "skewX(-5deg)",
              opacity: 1,
            }),
            style({
              transform: "none",
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        "active => removed",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: "translate3d(100%, 0, 0) skewX(30deg)",
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class MytoastrComponent extends Toast implements OnInit {
  @Output() handleButton: EventEmitter<any> = new EventEmitter();
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  hour: Date;
  icon: string;
  body: any;
  openToastrMobile: boolean;
  touchY = "auto";
  touchX = "0px";

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private readonly myToastService: MyToastService,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    super(toastrService, toastPackage);
    toastPackage.config.timeOut = this.options?.timeOut || 5000;
    toastPackage.config.tapToDismiss = false;
  }

  ngOnInit(): void {
    this.updateHour();

    if (this.options?.messageClass === "success") {
      this.icon = "warning-sucess";
    } else if (this.options?.messageClass === "error") {
      this.icon = "warning-error";
    } else if (this.options?.messageClass === "alert") {
      this.icon = "warning-alert";
    } else if (this.options?.messageClass === "info") {
      this.icon = "warning-information";
    } else if (this.options?.messageClass === "neutral") {
      this.icon = "warning-neutral";
    }
    let preventLogError = false;
    try {
      if (this.options?.titleClass) {
        this.body = JSON?.parse(this.options?.titleClass);
      }
    } catch {
      preventLogError = true;
    }
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  limitText(message: string) {
    const limit = 150;
    if (message.length >= limit) {
      message = message.substring(0, limit) + "...";
    } else {
      message = message.substring(0, limit) + "";
    }
    return message;
  }

  private updateHour(): void {
    this.hour = new Date();
  }

  handleClick(event) {
    this.myToastService.emitButtonClick(this.body.toast);
    this.handleButton.emit(this.body.toast)
  }
  stopPropagation(event: any): void {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
  }

  handleDismissToastr() {
    this.isHandset$.subscribe((res) => {
      if (res) {
        this.openToastrMobile = !this.openToastrMobile;
        const toastrs = document.querySelectorAll(".toastr-mobile-event");
        toastrs.forEach((item) => {
          if (this.openToastrMobile) {
            item.classList.add("open-toastr-mobile");
          } else {
            item.classList.remove("open-toastr-mobile");
          }
        });
      } else {
        this.remove();
      }
    });
  }

  dragDimissToastr(event) {
    this.isHandset$.subscribe((res) => {
      if (res) {
        if (event.type !== "drag") {
          if (event?.touches.length === 0) {
            this.touchX = `0px`;
            this.touchY = "auto";
          } else {
            this.touchX = `${(event?.touches[0].clientX - 50) | 0}px`;
            this.touchY = `${(event?.touches[0].clientY - 50) | 0}px`;
          }
          if (
            event?.touches.length > 0 &&
            (event?.touches[0].clientX > 250 ||
              event?.touches[0].clientX <= -40)
          ) {
            this.remove();
          }
        } else if (event?.screenX > 250) {
          this.remove();
        }
      }
    });
  }
}
