export class DynamicTooltip {

    public generateTooltip(tooltip, data, index) {
        return this.standardTooltip(tooltip, data, index)
    }

    private standardTooltip(tooltip, data, index) {
        let tooltipStandard = ''
        const value = data[index][tooltip.value]
        tooltipStandard += `${tooltip.message}${value}`
        return tooltipStandard
    }
}

