import { DynamicTooltip } from './../../functions/dynamicTooltip';
import { SubTotalCalc } from './../../functions/subTotalCalc';
import { FilterTableComponent } from './../tables/filter-table/filter-table.component';
import { HttpService } from './../tables/dynamic-table/service/httpService.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatTable } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";

// Interface
import { DynamicTable } from "../../../../utils/models/table.interface";
import { RequestAttribute } from "../../../../utils/models/http.interface";

// Services

// Animations
import { animations } from "../../../../utils/animations";
import { of, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import {
  calculateAverage,
  cleanTableData,
  filterOptionConfiguration,
  getHours,
  handleAverage,
  handleFilter,
  handleSort,
  onInit,
  removeReferenceDataToFilter,
  resizeTable,
  wagonNecessityTotals,
} from "../../functions/tableFunctions";
import lodash from "lodash";
import { VisualizationTableMapRequest } from "./visualization-table.map.request";
import { SelectionModel } from "@angular/cdk/collections";


@Component({
  // tslint:disable-next-line:component-selector
  selector: "visualization-table",
  templateUrl: "./visualization-table.component.html",
  styleUrls: ["./visualization-table.component.scss"],
  animations,
})
export class VisualizationTableComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public _unsubscribe$: Subject<any> = new Subject();

  /** Table Data Source */
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChildren("filterTable") filterTable: QueryList<FilterTableComponent>;
  @ViewChild("tabled", { static: false }) tabled;

  /** Endpoint for request  */
  /** Filter initial values ​​to make a request  */
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  attributeParams: RequestAttribute[] = [];
  _dynamicTable: DynamicTable;
  data: any = [];
  originalData: any = [];
  filterOptions = [];
  filterData = [];
  filterSelected = [];
  loading = false;
  scenarioOfficial = sessionStorage.getItem("scenarioOfficial");
  corridorName = sessionStorage.getItem("corridorName");
  dateScenario: Date;
  width;
  innerHeight;
  isSomeFilterClicked = false;

  // Inputs
  @Input() headerLine = true;
  @Input() dynamicTable: DynamicTable;
  @Input() maxHeight;
  @Input() attributes;
  @Input() emptyMessage = "Não há dados cadastrados.";
  @Input() hasSubTableHeader = true;
  @Input() hasSubTableBody = true;
  @Input() type;
  @Input() hideTotalValue = false;
  @Input() tableId = undefined;
  @Input() hasTopHeadClass = true;
  @Input() localData = undefined;
  @Input() hasDepenceTable = false;
  @Input() hasMultiSelect = true;
  @Input() dataDashboard: any;
  @Input() haveDownload;
  @Input() emptyStateImageMessage =
    "Não há dados para serem exibidos nesta tabela";
  @Input() emptyStateImage = this.data.lenght === 0;
  @Input() blueHeaderBackground = false;
  @Input() whiteBackground = true;
  @Input() removeLeftCellBorder = false;
  @Input() removeTypeAllocation = false;
  @Input() cleanButtonEmitterAux = false;

  /** Edit or Delete events */

  // emptyTable é emitido se a tabela está vazia, independente de um filtro ser
  //aplicado ou não
  @Output() emptyTable: EventEmitter<any> = new EventEmitter();

  // emptyTable é emitido se a tabela está vazia, se e somente se não há filtros aplicados
  @Output() emptyTableExceptFilter: EventEmitter<any> = new EventEmitter();
  @Output() loadedTable: EventEmitter<any> = new EventEmitter();
  @Output() downloadTable: EventEmitter<any> = new EventEmitter();
  public readonly selection = new SelectionModel<any>(true, []);

  constructor(
    private readonly httpService: HttpService,
    public elementRef: ElementRef<HTMLElement>,
    public dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    resizeTable(this, event);
  }

  ngOnDestroy(): void {
    cleanTableData(this);
    this.isSomeFilterClicked = false;
  }

  ngOnInit(): void {
    onInit(this);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  async loadTable(att = null) {
    this.filterData = [];
    this.filterSelected = [];
    this.filterOptions = [];
    this.startLoading();
    let tableData: any;
    if (
      this._dynamicTable.endpoint &&
      this._dynamicTable.endpoint !== "volume_table"
    ) {
      tableData = await this.loadRequest(att);
    } else {
      const type = this.dataDashboard?.title.includes("Destino")
        ? "Destino"
        : "Origem";
      this._dynamicTable.displayedColumns?.forEach((column) => {
        if (column.title === "Origem") {
          column.title = type;
        }
      });
      tableData = this.dataDashboard?.rows;
    }
    this.data = tableData?.rows || tableData;
    removeReferenceDataToFilter(this);
    this.cancelLoading();

    if (this.hasDepenceTable) {
      this.loadedTable.emit(true);
    }
  }

  downloadEmitter() {
    this.downloadTable.emit();
  }

  loadRequest(att) {
    return new Promise((resolve) => {
      this.httpService
        .genericGetListTable(
          this._dynamicTable.endpoint,
          this._dynamicTable.requestParams,
          att ? att : this.attributes
        )
        .pipe(
          catchError(() => of([])),
          takeUntil(this._unsubscribe$)
        )
        .subscribe((response: any) => {
          response = VisualizationTableMapRequest.mapRequest(
            response,
            this._dynamicTable.endpoint,
            {},
            this._dynamicTable,
            this.removeTypeAllocation
          );

          if (response) {
            resolve(response);
          }
        });
    });
  }

  startLoading() {
    this.loading = true;
  }

  cancelLoading() {
    this.loading = false;
  }

  trackArray(index) {
    return index;
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async reloadSticky() {
    await this.sleep(1);
    this.tabled?.updateStickyColumnStyles();
  }

  handleSort(att: string): void {
    handleSort(this, this._dynamicTable, att);
  }

  handlePin(column: any, thColuns: HTMLElement) {
    if (!column.sticky) {
      column["sticky"] = true;
    } else {
      column.sticky = !column.sticky;
    }
  }

  setFilterOptions(column) {
    filterOptionConfiguration(
      this,
      column,
      this._dynamicTable.displayedColumns
    );
  }

  configureSpecificTables(attribute) {
    if (
      this._dynamicTable.endpoint === "shiparrivals/portDemand" &&
      attribute === "che_st_observacao"
    ) {
      this.filterOptions["che_st_observacao"] = this.filterOptions[
        "che_st_observacao"
      ].map((x) => {
        if (x.value === "") {
          x.value = "-";
          x.friendly = "-";
        }
        return x;
      });
    }
  }

  handleFilter(event, columnFilter) {
    //aqui
    setTimeout(() => {
      handleFilter(
        event,
        columnFilter,
        this,
        false,
        this.cleanButtonEmitterAux
      );
    }, 50);
  }

  handleSubTotal(type) {
    if (this.data.length > 0) {
      return this.data
        .map((v) => {
          return v[type];
        })
        .reduce((acc, value) => {
          return typeof value === "number"
            ? Number(acc) + Number(value)
            : Number(acc);
        }, 0);
    }
    return 0;
  }

  calculateAverage(att: string): number {
    return calculateAverage(this, att);
  }

  handleAverage(type: string): number {
    return handleAverage(this, type);
  }

  handleSubTotalFloat(type: string): number {
    const roundValue = 100;
    if (this.data.length > 0) {
      return (
        (this.data
          .map((v) => {
            return v[type];
          })
          .reduce((acc, value) => Number(acc) + Number(value), 0) *
          roundValue) /
        roundValue
      );
    }
    return 0;
  }

  returnColumns(column: any) {
    const hasSubTable = [];

    for (const item of column.hasSubTable) {
      hasSubTable.push({ title: column.attribute, type: item.type });
    }
    return hasSubTable;
  }

  getHours(totalMinutes) {
    return getHours(totalMinutes);
  }

  handleEmptyMessage(): string {
    if (this.tabled?._data.length > 0) {
      return "";
    }

    if (this.attributes?.length > 0) {
      this.emptyTable.emit(this.attributes[3]?.value); //NOSONAR
    }
    const filterstest = Object.values(this.filterSelected).map(
      (x) => x == null || (Array.isArray(x) && x.length === 0)
    );
    const IS_ALL_UNSELECTED_FILTERS = lodash.every(filterstest);
    if (this.attributes?.length > 0 && IS_ALL_UNSELECTED_FILTERS) {
      this.emptyTableExceptFilter.emit(this.attributes[3]?.value); //NOSONAR
    }
    return this.emptyMessage;
  }

  handleEmptyTable(): boolean {
    if (
      this.data?.length < 1 ||
      (!!this.data && this.data.constructor === Object)
    ) {
      return true;
    }
    return false;
  }

  wagonNecessityTotals = wagonNecessityTotals;
  handleSubTotalSubTable(sub: number, column: string, type: string): number {
    const calcTotal = new SubTotalCalc();
    return calcTotal.handleSubTotalSubTable(
      this.data,
      sub,
      column,
      type,
      this.wagonNecessityTotals
    );
  }

  handleNumberFormat(value: string | number): number | string {
    return typeof value === "number"
      ? value.toFixed(2).toString().replace(".", ",")
      : value;
  }

  getTooltip(tooltipData, index) {
    const tooltip = new DynamicTooltip();
    return tooltip.generateTooltip(tooltipData, this.data, index);
  }

  getTooltipInfo(tooltipData, element) {
    let tooltip = "";
    tooltipData.forEach((data) => {
      tooltip += `${data.message}${element[data.value]}`;
    });
    return tooltip;
  }

  /**
   * @param column Coluna que será analisada como objeto
   * @returns retorna um objeto que analisa os dados do
   * indice 0 da tabela e informa se é igual o objeto
   * passado como parâmetro
   */
  handleWeightedAverage(column) {
    if (!!this.data[0]) {
      let obj = null;
      obj = this.data[0].weightedAverage.find(
        (element) => column === element.column
      );
      return obj.average;
    }
    return 0;
  }

  handleDayAverage(column) {
    if (!!this.data[0]) {
      let totalMinutes = 0;
      this.data.forEach((element) => {
        totalMinutes += this.convertDaysToMinutes(element[column]);
      });
      return this.convertMinutesToDaysAndHours(totalMinutes / this.data.length);
    }
    return "";
  }

  handleDaysAvarageFloat(column) {
    if (!!this.data[0]) {
      let totalDays = 0;
      this.data.forEach((element) => {
        totalDays += element[column];
      });
      return totalDays / this.data.length;
    }
    return "";
  }

  convertDaysToMinutes(days: string) {
    const splitDayAndHours = days.split("d");
    const hours = splitDayAndHours[1].split("h");
    const minutes = Number(hours[1].replace(":", "").replace("m", ""));
    const daysToMinutes = Number(splitDayAndHours[0]) * 24 * 60;
    const hoursToMinutes = Number(hours[0]) * 60;
    return minutes + daysToMinutes + hoursToMinutes;
  }

  convertMinutesToDaysAndHours(minutes: number): string {
    if (minutes === 0) {
      return "0d 0h:0m";
    } else if (minutes > 60) {
      const hours = Math.floor(minutes / 60);
      const restMinutes = Math.floor(minutes % 60);
      if (hours < 24) {
        return `0d ${hours}h:${restMinutes}m`;
      } else {
        const days = Math.floor(hours / 24);
        const restHours = Math.floor(hours % 24);
        const restMinutesHours = Math.floor(hours % 60);
        return `${days}d ${restHours}h:${restMinutesHours}m`;
      }
    } else {
      return `0d 0h:${minutes}m`;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data ? this.data.length : null;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.data);
  }

  formatOutput(inputText: string | number): string | number {
    if (typeof inputText === "number" && inputText >= 1000) {
      const formattedNumber = inputText.toLocaleString("pt-BR");
      return formattedNumber;
    } else {
      return inputText;
    }
  }

  formatPercentValue(value: Number) {
    return typeof value === "number" ? `${value.toFixed(1)} %` : value;
  }

  cleanButtonEmitter(event) {
    this.cleanButtonEmitterAux = event;
  }
}
