import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker/datepicker";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CalendarPickerConfig } from "src/utils/models/calendar.interface";
import { getDateColombia } from "../../service/utils";
import { Moment } from "moment";

const TIME_OUT = 600;

@Component({
  selector: "calendar-picker",
  templateUrl: "./calendar-picker.component.html",
  styleUrls: ["./calendar-picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarPickerComponent),
      multi: true,
    },
  ],
})
export class CalendarPickerComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() calendarPicker: CalendarPickerConfig;
  @Input() showTime: boolean;
  @Input() id: string;
  @Input() required = false;
  @Output() onChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() clearInputEmiter: EventEmitter<any> = new EventEmitter();

  isStartDate = true;
  @ViewChild("calendarViewChild", { static: false })
  calendarViewChild: any;
  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }

  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeEnd: EventEmitter<any> = new EventEmitter<any>();
  _yearPickerCtrl: FormControl = new FormControl();
  _monthPickerCtrl: FormControl = new FormControl();
  _regularPickerCtrl: FormControl = new FormControl();
  _periodPickerCtrl: FormGroup;
  _startDate: any = [];
  _endDate: any = [];

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) public readonly _document: any,
    private readonly formBuilder: FormBuilder
  ) {
    this._periodPickerCtrl = this.formBuilder.group({
      startDate: [null ],
      endDate: [null],
    });
  }

  ngAfterViewInit() {
    switch (this.calendarPicker.mode) {
      case "ANO":
        this._subscribeToChanges(this._yearPickerCtrl);
        break;
      case "PERIODO":
        this._subscribeToChanges(this._periodPickerCtrl);
        break;

      case "MES":
        this._subscribeToChanges(this._monthPickerCtrl);
        break;

      default:
        this._subscribeToChanges(this._regularPickerCtrl);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }

  // Function to call when the date changes.
  onChange = (date: any) => {
    this.onChangeEmitter.emit(date);
    this.clearInputEmiter.emit(true);
  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  writeValue(date: any): void {
    if (date) {
      this._writeValue(date);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    switch (this.calendarPicker.mode) {
      case "ANO":
        isDisabled
          ? this._yearPickerCtrl.disable()
          : this._yearPickerCtrl.enable();
        break;
      case "PERIODO":
        isDisabled
          ? this._periodPickerCtrl.disable()
          : this._periodPickerCtrl.enable();
        break;

      case "MES":
        isDisabled
          ? this._monthPickerCtrl.disable()
          : this._monthPickerCtrl.enable();
        break;

      default:
        isDisabled
          ? this._regularPickerCtrl.disable()
          : this._regularPickerCtrl.enable();
    }
  }

  _takeFocusAway($datepicker: MatDatepicker<any>) {
    $datepicker.disabled = true;
    setTimeout(() => {
      $datepicker.datepickerInput["_elementRef"].nativeElement.blur();
      $datepicker.disabled = false;
    }, TIME_OUT);
  }

  public _writeValue(date: any): any {
    if (!date) {
      return;
    }

    switch (this.calendarPicker.mode) {
      case "ANO":
        if (date instanceof Date) {
          this._yearPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;
      case "PERIODO":
        if (date instanceof Date) {
          this._periodPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;

      case "MES":
        if (date instanceof Date) {
          this._monthPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;

      default:
        if (date instanceof Date) {
          this._regularPickerCtrl.setValue(date, { emitEvent: false });
        }
    }
  }

  public _subscribeToChanges(control: any) {
    if (!control) {
      return;
    }
    control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      let valor: any = [];
      if (this.calendarPicker.mode === "PERIODO") {
        valor = this.emitterPeriod(value);
      } else {
        valor = getDateColombia(value);
      }

      this.dateChange.emit(valor);
      this.onChange(valor);

      this.onTouched();
    });
  }

  private emitterPeriod(value) {
    return {
      startDate: this._startDate,
      endDate: this._endDate,
    };
  }
  private onChangeStartPeriod(value: Moment) {
    this._startDate = value
    this._endDate = null;
    this.onChangeStart.emit(value);
  }
  private onChangeEndPeriod(value) {
      this._endDate = value
      this.onChangeEnd.emit(value);
  }

  get _showMonthPicker(): boolean {
    return this.calendarPicker.mode === "MES";
  }

  get _showPeriodPicker(): boolean {
    return this.calendarPicker.mode === "PERIODO";
  }

  get _showRegularDatepicker(): boolean {
    return !this.calendarPicker.mode;
  }
}
