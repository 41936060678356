import { DynamicTable } from "src/utils/models/table.interface";
import { dynamicTableDefaultMapRequest } from "../../functions/globalFunctions";

export class VisualizationTableMapRequest {
  public static mapRequest(
    data: any,
    endpoint: string,
    input: any,
    _dynamicTable?: DynamicTable,
    removeTypeAllocation = false
  ) {
    if (endpoint === "shiparrivals/portDemand") {
      data = data.map((x) => {
        if (
          x["che_st_observacao"] === "undefined" ||
          x["che_st_observacao"] === ""
        ) {
          x["che_st_observacao"] = "-";
        }

        return x;
      });
    } else if (endpoint === "allocation-locomotive") {
      if (!!data?.rows[0]?.locomotives) {
        const mappedSeries = data?.rows[0]?.locomotives?.map((x) => x?.series);
        if (removeTypeAllocation) {
          _dynamicTable.columns = [
            "product",
            "region",
            ...mappedSeries,
            "$total",
          ];
        } else {
          _dynamicTable.columns = [
            "select",
            "typeAllocation",
            "product",
            "region",
            ...mappedSeries,
            "$total",
          ];
        }
        dynamicTableDefaultMapRequest(mappedSeries, _dynamicTable, data);
      }
    }

    return data;
  }
}
