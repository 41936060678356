import { isArray } from "lodash";

export class Sort {
    private sortOrder = 1;
    private readonly collator = new Intl.Collator(undefined, {
        sensitivity: 'base',
        numeric: true
    })

    public startSort(property: string, order: string, type = '', internal = false) {
        const sortOrder = (order === 'desc') ? -1 : 1;
    
        return (a, b) => {
            let firstValue = a[property];
            let secondValue = b[property];
    
            if (internal) {
                const extractValue = (obj) => obj.value[obj.labelShow];
                const extractArrayObjectValue = (obj) => obj.value[0][obj.labelShow];
                const extractArrayValue = (obj) => obj.value[0];

                if (firstValue?.labelShow && !Array.isArray(firstValue.value)) {
                    firstValue = extractValue(firstValue);
                    secondValue = extractValue(secondValue);
                } else if(Array.isArray(firstValue.value)) {
                    if(firstValue?.labelShow ) {
                        firstValue = extractArrayObjectValue(firstValue);
                        secondValue = extractArrayObjectValue(secondValue);                    
                    } else {
                        firstValue = extractArrayValue(firstValue);
                        secondValue = extractArrayValue(secondValue);
                    }
                } else {
                    firstValue = firstValue.value;
                    secondValue = secondValue.value;
                }
            }

            if(type === 'period') {
                firstValue = firstValue.startDate;
                secondValue = secondValue.startDate;
            }
    
            if (type === 'date') {
                return this.sortData(new Date(firstValue), new Date(secondValue)) * sortOrder;
            } else {
                return this.collator.compare(firstValue, secondValue) * sortOrder;
            }
        };
    }

    private sortData(a, b) {
        if(a > b) {
            return -1 * this.sortOrder
        } else if(a < b) {
            return 1 * this.sortOrder
        } else {
            return 0 * this.sortOrder
        }
    }
}
