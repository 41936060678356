import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { FilterInterface } from "src/utils/models/filter.interface";
import { CalendarPickerComponent } from "../../calendar-picker/calendar-picker.component";
import { SelectInputComponent } from "../../inputs/select-input/select-input.component";
import { RadioButtonInput } from "src/utils/models/input.interface";

@Component({
  selector: "dynamic-field",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent implements OnInit {
  @Input() formInterface: FilterInterface | null;

  @ViewChild("calendarPicker", { static: false })
  calendarPicker: CalendarPickerComponent;
  @ViewChildren("selectInput") selectInput: QueryList<SelectInputComponent>;
  @ViewChildren("radioButtonsViewChild")
  radioButtonsViewChild: QueryList<RadioButtonInput>;

  @Output() selectEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() multiSelectEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() calendarEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() toogleEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() resetGraphEmitter: EventEmitter<any> = new EventEmitter();

  form = this.formBuilder.group({});

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initFormInterface();
  }

  initFormInterface() {
    /* 
    remvoendo form controls anterior para quando o json for
    alterado, não houver sobreposição
    */
    this.removePreviousForm();
    this.formInterface.fields.forEach((field) => {
      if (field.type === "calendar") {
        this.form.addControl(
          `${field.controlName}Start`,
          new FormControl(field?.interface?.value || null)
        );
        this.form.addControl(
          `${field.controlName}End`,
          new FormControl(field?.interface?.value || null)
        );
      } else if (field.type === "toogle") {
        this.form.addControl(field.controlName, new FormControl(null));
      } else if (field.type === "radioButton") {
        this.form.addControl(field.controlName, new FormControl(null));
      } else {
        this.form.addControl(
          field.controlName,
          new FormControl(field?.interface?.value || null)
        );
      }
    });
  }

  removePreviousForm() {
    Object.keys(this.form.controls).forEach((controlName) => {
      this.form.removeControl(controlName);
    });
  }

  cleanFilter() {
    this.form.reset();
    this.calendarPicker._periodPickerCtrl.reset();
    this.resetGraphEmitter.emit(true);
  }

  changeSelect(event, field) {
    this.selectEmmiter.emit({ value: event, field });
  }

  onChangeMultiSelect(event, field){
    this.multiSelectEmmiter.emit({ value: event, field });
  }

  onChangeEnd(event, field) {
    const [day, month, year] = event.split('/');
		const date = new Date(Number(year), Number(month) - 1, Number(day))
    this.form.get(`${field.controlName}End`).setValue(date.getTime());
    this.calendarEmmiter.emit({ value: date, field });
  }

  onChangeStart(event, field) {
    const [day, month, year] = event.split('/');
		const date = new Date(Number(year), Number(month) - 1, Number(day))
    this.form.get(`${field.controlName}Start`).setValue(date.getTime());
    this.calendarEmmiter.emit({ value: date, field });
  }

  onChangeToogle(event, field) {}

  onChangeRadio(event, index, controlName) {
    if (event.length) {
      this.form.get(controlName).setValue(event[0].value);
    }
    this.radioButtonsViewChild
      .toArray()
      .forEach((element: RadioButtonInput, idx) => {
        if (index !== idx) {
          element["radioButton"].options[0].checked = false;
        }
      });
  }
}
