import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { CalendarPickerComponent } from "../calendar-picker.component";

import moment, { Moment } from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { HeaderComponentComponent } from "../header-component/header-component.component";
import { TimePickerComponent } from "../../inputs/time-picker/time-picker.component";

const DATE_YEAR = "DD/MM/YYYY";
export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: DATE_YEAR,
  },
  display: {
    dateInput: DATE_YEAR,
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: DATE_YEAR,
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const datePickerClass = {
  DAY: "calendar-picker",
  MONTH: "calendar-picker-month",
  YEAR: "calendar-picker-year",
};
type modeDatePicker = "DIA" | "" | null;

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  providers: [ 
    { provide: MAT_DATE_LOCALE, useValue: "pt-br" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})
export class CalendarComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  headerPicker = HeaderComponentComponent;
  @ViewChild("timePicker") timePicker: TimePickerComponent;
  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;
  @ViewChild("viewChildInput", { static: false })
  viewChildInput: any;

  @Input() customFormFieldClass = "";
  @Input() label = "";
  @Input() showTime = false;
  @Input() touchUi = false;
  @Input() id: string;
  @Output() clearnInputEmitter: EventEmitter<any> = new EventEmitter();

  _min: Moment;
  @Input()
  get min(): Date {
    return this._min ? this._min.toDate() : undefined;
  }
  set min(minimum: Date) {
    if (minimum) {
      const momentDate = moment(minimum);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _max: Moment;
  @Input()
  get max(): Date {
    return this._max ? this._max.toDate() : undefined;
  }
  set max(max: Date) {
    if (max) {
      const momentDate = moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _mode: modeDatePicker = "";
  @Input()
  get mode(): "DIA" | "" | null {
    return this._mode;
  }
  set mode(mode: "DIA" | "" | null) {
    this._mode = mode;
    this._setupFilter();
  }

  _customFilter: (d: Moment) => boolean;
  _inputCtrl: FormControl = new FormControl();
  _panelClass = datePickerClass.DAY;
  _timePickerMoment = moment();

  onChange = (date: Date) => {};
  onTouched = () => {};
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  constructor(
    public readonly parent: CalendarPickerComponent,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats
  ) {}

  ngOnInit(): void {
    if (this.showTime) {
      this.dateFormats.display.dateInput = "DD/MM/YYYY HH:mm";
    } else {
      this.dateFormats.display.dateInput = DATE_YEAR;
    }
  }

  ngAfterViewInit() {
    this._takeFocusAway = this.parent._takeFocusAway;
  }

  writeValue(date: Date): void {
    if (date) {
      date.setHours(date.getHours() - 2)
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(momentDate, { emitEvent: false });
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled ? this._inputCtrl.disable() : this._inputCtrl.enable();
  }

  _dateChangeHandler(chosenDate: Moment) {
    const data = chosenDate.toDate();

    if (this.showTime) {
      data.setHours(parseInt(this._timePickerMoment.format("HH")));
      data.setMinutes(parseInt(this._timePickerMoment.format("mm")));
    }
    this._inputCtrl.setValue(data, { emitEvent: false });
    this.onChange(data);
    this.onTouched();
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClass(datePickerClass.DAY);
      datepicker.open();
      this.onTouched();
    }
    this.clearInput();
  }

  clearInput() {
    this.clearnInputEmitter.emit(true);
    this._inputCtrl.setValue(null);
  }

  private _setupFilter() {
    if (this.mode === "DIA") {
      this._customFilter = (d: Moment) => {
        return !d.day();
      };
    }
  }
  setPanelClass(view) {
    if (this._panelClass === "") {
      this._panelClass = datePickerClass.DAY;
    } else {
      this._panelClass = view;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  get hasTimePicker() {
    if (this._panelClass !== datePickerClass.DAY) {
      return true;
    }
    return false;
  }

  capitalizeMonth() {
    const content = document.getElementsByClassName(
      "mat-calendar-body-cell-content"
    ) as HTMLCollectionOf<HTMLElement>;
    Array.from(content).forEach((month) => {
      month.innerText = month.innerText.toLowerCase();
      month.innerText =
        month.innerText.charAt(0).toUpperCase() + month.innerText.slice(1);
    });
  }

  changePanelClass(event) {
    if (event === "year") {
      this.setPanelClass(datePickerClass.MONTH);
      this.capitalizeMonth();
    } else if (event === "multi-year") {
      this.setPanelClass(datePickerClass.YEAR);
    } else {
      this.setPanelClass(datePickerClass.DAY);
    }
  }

  changeDateTimePicker(time) {
    this._timePickerMoment = time;
  }
}
