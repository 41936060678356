import {
  Component,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  Input,
  ElementRef,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";

// Interfaces
import {
  Brand,
  Menu,
  MenuBottom,
  MenuMobile,
  PageTitle,
} from "../../../../../utils/models/layout.interface";

// Constants
import { BannerInterface } from "../../../../../utils/models/common.interface";
import { NavigationService } from "../../../service/navigation.service";

@Component({
  selector: "navigation-induxtry",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  @ViewChild("drawer") private readonly drawer: MatSidenav;
  @ViewChild("ganttSide") private readonly ganttSide: MatSidenav;

  // Observable
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  title$: Observable<string>;
  title: string;
  @Input() menus: Menu[];
  @Input() user: Menu;
  @Input() brand: Brand;
  @Input() menuBottom: MenuBottom;
  @Input() disabledTopNav = true;
  @Input() pageTitles: PageTitle[];
  sidenavPositionControl = "start";
  @Input() mobileSideNavPositionControl;
  @Input() menuMobile: MenuMobile;
  myCollapsedUserPanel;
  myCollapse;

  showSideNav = true;
  scroll = false;
  showLabel = false;

  banner: BannerInterface[] = [];
  identifier = "graphDataWithdrawalSells";

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    private readonly el: ElementRef,
    private readonly nav: NavigationService
  ) {
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => (this.title = e.url));
  }

  ngOnInit() {
    this.isHandset$.subscribe((res) => {
      if (res) {
        this.sidenavPositionControl = this.mobileSideNavPositionControl;
      } else {
        this.sidenavPositionControl = "start";
      }
    });

    this.nav.banner.subscribe((res) => {
      if (res) {
        this.banner?.push(res);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  handleClose(event: boolean): void {
    this.isHandset$.subscribe((value) => {
      if (value && event) {
        this.drawer.close();
      }
    });
  }

  closeGanttSide() {
    this.ganttSide?.close();
  }

  receiveCollapse(collapse) {
    this.myCollapse = collapse;
    this.isHandset$.subscribe((value) => {
      if (value) {
        document.getElementById("sidenav").style.width = "254px";
        document.getElementById("sidenav").style.right = "0px";
        document.getElementById("sidenav").style.left = "";
        this.sidenavPositionControl = "end";
      } else {
        this.sidenavPositionControl = "start";
        if (this.myCollapse) {
          document.getElementById("sidenav").style.width = "198px";
          document.getElementById("sidenav").style.left = "0px";
        } else {
          document.getElementById("sidenav").style.width = "80px";
          document.getElementById("sidenav").style.left = "0px";
        }
      }
    });
  }

  receiveCollapsedUserPanel(collapsedUseMenu) {
    if (!collapsedUseMenu) {
      this.myCollapsedUserPanel = collapsedUseMenu;
    } else {
      this.myCollapsedUserPanel = !this.myCollapsedUserPanel;
    }
  }

  scrollToTop() {
    const contentElement = this.el.nativeElement.querySelector(".content");
    if (contentElement) {
      contentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.showLabel = false;
    }
  }

  onScrollTeste(event) {
    const scroll = event?.target?.scrollTop;
    this.scroll = scroll === 0 ? false : true;
  }

  expandButton(isHovered) {
    if (isHovered) {
      this.showLabel = true;
    } else {
      this.showLabel = false;
    }
  }

  closeBanner(event) {
    const newArray = this.banner.filter((item) => item !== event);
    this.banner = newArray;
  }

  eventButton(event) {
    return event
  }
}
