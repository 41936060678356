export class SubTotalCalc {

    

    public handleSubTotalSubTable(data: any, sub: number, column: string, type: string, wagonNecessityTotals: any): number {
        if (data.length > 0) {
          const subTotal = data.map((v) => v[column][sub]).reduce((acc, value) => Number(acc) + Number(value), 0);

          if (this.isWagonNecessitySubTotalColumn(column)) {
            wagonNecessityTotals[column][sub] = subTotal;
            if (type === 'number-percent' && (column === 'loadedBudget' || column === 'emptyBudget')) {
              return this.handleWagonNecessityPercentDisplay(column, 'expected', wagonNecessityTotals);
            } else if (type === 'number-percent' && (column === 'loaded' || column === 'empty')) {
              return this.handleWagonNecessityPercentDisplay(column, 'prog', wagonNecessityTotals);
            }
          }
          
          return subTotal;
        }
        return 0;
    }

    private isWagonNecessitySubTotalColumn(column: string): boolean {
        return (
            column === 'expected' || column === 'prog' ||
            column === 'loadedBudget' || column === 'emptyBudget' ||
            column === 'loaded' || column === 'empty' 
        ) ? true : false;
    }

    private handleWagonNecessityPercentDisplay(column: string, totalColumn: string, wagonNecessityTotals): number {
        const convertToPercent = 100;
        const roundOff = 2;
        return Math.round(
          ((wagonNecessityTotals[column][0] /
            wagonNecessityTotals[totalColumn][0]) *
            convertToPercent *
            roundOff) /
            roundOff
        );
      }
}