import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { GlobalDataService } from "../../service/globalDataService";
import { DynamicFieldComponent } from "./dynamic-field/dynamic-field.component";
import { FilterInterface } from "src/utils/models/filter.interface";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "filter-diaco",
  templateUrl: "./filter-diaco.component.html",
  styleUrls: ["./filter-diaco.component.scss"],
})
export class FilterDiacoComponent implements OnInit, OnDestroy {
  @ViewChild("fieldsComponent") fieldsComponent: DynamicFieldComponent;
  @Input() dynamicFields: FilterInterface;
  @Output() formEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter();
  @Output() selectInputEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() calendarInputEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() emitterResetFilter: EventEmitter<any> = new EventEmitter();

  openFilter: boolean;
  toggleSubscription: Subscription;
  isToogleActivated = false;
  globalDataService: GlobalDataService;
  subscription: Subscription;
  disableSubmitButton = false;

  private readonly unsubscribe$: Subject<Event> = new Subject<Event>();

  constructor(globalDataService: GlobalDataService) {
    this.globalDataService = globalDataService;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.showFilter();
  }

  showFilter() {
    this.subscription = this.globalDataService.showFilter
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.openFilter = res;
      });
  }

  closeFilter(icon = false) {
    this.openFilter = false;
    if (icon) {
      this.fieldsComponent.form.reset();
    }
  }

  emitForm() {
    this.formEmmiter.emit(this.fieldsComponent.form);
  }

  handleClearFilter(): void {
    this.fieldsComponent.cleanFilter();
    this.clearFilter.emit(true);
  }

  handleClickFilter(): void {
    this.emitForm();
    this.closeFilter();
  }

  selectEmmiter(event) {
    this.selectInputEmmiter.emit({
      event,
      fieldComponent: this.fieldsComponent,
    });
  }

  calendarEmmiter(event) {
    this.calendarInputEmmiter.emit({
      event,
      fieldComponent: this.fieldsComponent,
    });
  }

  resetGraphEmitter(event) {
    this.emitterResetFilter.emit(true);
  }
}
