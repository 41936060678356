import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {  Router } from '@angular/router';

// Interface
import { MenuLink } from '../../../../../utils/models/layout.interface';
import { SwalComponent } from '../../dialogs/swal/swal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit, OnChanges {

  @Input() link: MenuLink;
  @Input() subMenu: boolean;
  @Input() collapse;
  @Input() child = false;
  @Output() linkClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  myCollapse;
  isHovered = false;
  panelOpenState = false;
  dialog: any;

  constructor(
    private readonly router: Router,
    public matDialog:MatDialog) { }

  activeRoute(routeName: string): boolean {
    return this.router.url.indexOf(routeName) > -1;
  }

  handleClick(event: boolean): void {
    this.linkClose.emit(event);
  }

  handleClickRoute(route, event) {
        if(sessionStorage.getItem('blockEdited')){
      const changePageDialog = this.matDialog.open(SwalComponent, {
        width: "400px",
        data: {
          type: "info",
          title: "Hay valores no guardados en la tabla",
          text: `¡Atención! Hay valores editados en la tabla que no se guardaron. Antes de continuar, guarde sus ediciones.`,
          buttons: [
            { label: "VOLVER", customStyle: "tertiary", key: false },
            { label: "CONTINUAR SIN GUARDAR", customStyle: "primary", key: true },
          ],
          hour: false,
          disableCloseIcon: false,  
        },
      });
      changePageDialog.componentInstance.handleClickEvent.subscribe((res) => {
        if(res[1]){
          changePageDialog.close()
          this.router.navigate([route]);
          sessionStorage.removeItem('blockEdited')
        } else {
          changePageDialog.close()
          sessionStorage.removeItem('blockEdited')
        }
      })
    } else {
      this.router.navigate([route]);
      sessionStorage.removeItem('blockEdited')
    }
   
  }

  ngOnChanges(change) {
    if (change.collapse) {
      this.myCollapse = this.collapse;
    }
  }

  ngOnInit() {
    this.myCollapse = true;
  }

  preventMenuClose(event: Event, type?) {
    if(type === 'secondary' || type === 'tertiary'){
      event.stopPropagation();
    }
  }

  navigation(link){
    return [link?.router]
  }
}
