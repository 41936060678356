import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileInputComponent,
    },
  ],
})

export class FileInputComponent implements OnInit, ControlValueAccessor {
  warningIconFileInput = returnWarningIcon;
  colorWarningFileInput = returnColorWarning;
  textWarningFileInput = returnTextWarning;

  @Input() fileInput: FileInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };


  @Output() fileImport: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild("inputFile") inputFile: ElementRef;

  file: any = null;
  isInvalidType = false;
  acceptedTypes = '';
  textPlaceHolder: string;

  constructor(private readonly toastr: ToastrService) { }

  ngOnInit(): void {
    this.textPlaceHolder = this.fileInput?.placeholder || 'Selecione ou arraste o arquivo'
    this.setAcceptedTypes();
  }

  writeValue(value: any): void {
    this.fileInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  selectFile(event): void {
    const objFile = event[0] || event?.target?.files[0];
    if (this.checkFileType(objFile?.name)) {
      this.file = objFile;
      this.onChange(this.file)
      this.fileImport.emit(this.file);
      this.isInvalidType = false;
    } else {
      this.isInvalidType = true;
      this.file = null;
      const fileTypes = this.fileInput.types.map(num => num.toString());
      const lastType = fileTypes.pop();
      const result = fileTypes.join(', ') + ' y ' + lastType;
      // this.toastr.show(`¡Atención! El formato del archivo cargado es incorrecto. Por favor, realice la carga de un archivo con formato ${result}`, 'Format del archivo incorrecto', { timeOut: 5000, messageClass: 'error' });

      let body = {
        toast: 'toast1',
        loading: false,
        // label: 'Label',
        // labelSecondary: 'Label',
        isHour: true,
        // button:{ label: 'CERRAR', customStyle: 'tertiary' }
      }
  
      const toast = this.toastr.show(
     `¡Atención! El formato del archivo cargado es incorrecto. Por favor, realice la carga de un archivo con formato .${this.fileInput.types[0]}`, 
        'Formato del archivo incorrecto', 
      { 
        // timeOut: 5000, 
        disableTimeOut: true,
        messageClass: 'error',
        titleClass: JSON.stringify(body) 
      })
    }
  }

  resetFile() {
    this.file = null;
    this.inputFile.nativeElement['value'] = ''
  }

  checkFileType(name) {
    const nameAux = name.split('.');
    return this.fileInput.types.includes(nameAux[nameAux.length - 1]);
  }

  setAcceptedTypes() {
    if (this.fileInput?.types?.length > 1) {
      this.acceptedTypes = this.fileInput?.types?.map(type => `.${type},`).join(' ');
    } else {
      this.acceptedTypes = `.${this.fileInput?.types[0]}`;
    }
  }
}
