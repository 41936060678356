import { Component, EventEmitter, Input, Output, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SwalComponent } from '../../dialogs/swal/swal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-page-select',
  templateUrl: './page-select.component.html',
  styleUrls: ['./page-select.component.scss']
})
export class PageSelectComponent implements AfterViewInit, OnChanges {

  @Input() length: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() selectedPage = 1;
  @Input() sizeMin = false
  @Input() changePageModal = false

  @Output() itensOutput = new EventEmitter();
  @Output() emitPageSelected = new EventEmitter();

  select = new FormControl();
  pages;
  count = 0;
  maxPages: number;
  beforeValue = this.selectedPage;

  constructor(private readonly cdr: ChangeDetectorRef,public dialog: MatDialog) {
    this.cdr = cdr;
  }

  verifySelectedValue(): void {
    if (this.selectedPage >= 1 && this.selectedPage <= this.maxPages) {
      this.beforeValue = this.selectedPage;
    } else if ((!this.selectedPage && this.selectedPage === 0) || this.selectedPage > this.maxPages) {
      this.selectedPage = this.beforeValue;
    }
  }

  generateMaxPages() {
    this.maxPages = Math.ceil(this.length / this.pageSize);
  }

  ngOnChanges() {
    this.pagesNumber();
    this.generateMaxPages();
    this.beforeValue = this.selectedPage;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  pagesNumber() {
    this.selectedPage = this.selectedPage === undefined ? 1 : this.selectedPage;

    if (this.count === 0) {
      this.pageSizeOptions.push(this.length);
      this.count++;
    }
    this.pageSizeOptions[this.pageSizeOptions.length - 1] = this.length;
    if (this.pageSize === 0) {
      this.pages = 1;
    } else {
      this.pages = Math.ceil(this.length / this.pageSize);
    }
    if (this.length < this.pageSize) {
      this.select.setValue(this.length);
    } else {
      this.select.setValue(this.pageSize);
    }
  }

  changePag(op) {

 
    if(this.changePageModal && sessionStorage.getItem('blockEdited')){
      //const loadingModal = openModalLoading(this.dialog);
      const changePageDialog = this.dialog.open(SwalComponent, {
      width: "400px",
      data: {
        type: "info",
        title: "Hay valores no guardados en la tabla",
        text: `¡Atención! Hay valores editados en la tabla que no se guardaron. Antes de continuar, guarde sus ediciones.`,
        buttons: [
          { label: "VOLVER", customStyle: "tertiary", key: false },
          { label: "CONTINUAR SIN GUARDAR", customStyle: "primary", key: true },
        ],
        hour: false,
        disableCloseIcon: false,   
      },
    });

    changePageDialog.componentInstance.handleClickEvent.subscribe((res) => {
      if(res[1]){
        this.changePage(op)
        sessionStorage.removeItem('blockEdited')
        changePageDialog.close()
      } else {
        changePageDialog.close()
      }
    })
  } else {
    this.changePage(op)
  }
  }

  changePage(op){
    if (op === '+' && this.selectedPage < this.pages) {
      this.selectedPage++;
      this.emitPageSelected.emit(this.selectedPage);
    } else if (op === '-' && this.selectedPage > 1) {
      this.selectedPage--;
      this.emitPageSelected.emit(this.selectedPage);
    }
    this.beforeValue = this.selectedPage;
  }

  switchToThisPage(idx) {
    if (!idx && idx !== 0) {
      this.selectedPage = 1;
    }
    if (idx >= 1 && idx <= this.maxPages) {
      this.selectedPage = idx;
      this.emitPageSelected.emit(this.selectedPage);
    }
  }

  changeManyItens(itens) {
    this.pageSize = itens;
    this.beforeValue = 1;
    this.selectedPage = this.beforeValue;
    this.pagesNumber();
    this.generateMaxPages();
    this.itensOutput.emit(itens);
  }

  styleObject(idx): Object {
    if (this.selectedPage === idx) {
      return {
              'background-color': '#1B4693',
              'color':'#DCE7F9',
              'font-weight': 'bold'
              };
    }
    return {};
  }
}
