import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { Subject } from "rxjs";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { takeUntil } from "rxjs/operators";
import { Moment } from "moment";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";

const STYLE_CALENDAR = {
  DAY: "month",
  MONTH: "year",
  YEAR: "multi-year",
};
@Component({
  selector: "header-picker",
  templateUrl: "./header-component.component.html",
  styleUrls: ["./header-component.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  
})
export class HeaderComponentComponent implements OnDestroy {
  private readonly _destroyed = new Subject<void>();
  private _timeOut;

  constructor(
    public calendar: MatCalendar<Moment>,
    public dateAdapter: DateAdapter<Moment>,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
      cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    if (this.calendar.currentView === STYLE_CALENDAR.DAY) {
      return `${this.dateAdapter.format(this.calendar.activeDate, "MMMM")} 
          de ${this.dateAdapter.format(this.calendar.activeDate, "YYYY")}`;
    } else {
      return this.dateAdapter.format(this.calendar.activeDate, "YYYY");
    }
  }

  get showHeader() {
    return this.calendar.currentView !== STYLE_CALENDAR.YEAR ? true : false;
  }

  nextView() {
    switch (this.calendar.currentView) {
      case STYLE_CALENDAR.DAY:
        this.calendar.currentView = "year";
        break;
      case STYLE_CALENDAR.MONTH:
        this.calendar.currentView = "multi-year";
        break;
      default:
        break;
    }
  }

  capitalizeMonth() {
    const content = document.getElementsByClassName(
      "mat-calendar-body-cell-content"
    ) as HTMLCollectionOf<HTMLElement>;
    Array.from(content).forEach((month) => {
      month.innerText = month.innerText.toLowerCase();
      month.innerText =
        month.innerText.charAt(0).toUpperCase() + month.innerText.slice(1);
    });
    clearTimeout(this._timeOut);
  }

  previousClicked() {
    if (
      this.calendar.currentView === STYLE_CALENDAR.DAY &&
      this.calendar.currentView !== STYLE_CALENDAR.YEAR
    ) {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
        this.calendar.activeDate,
        -1
      );
    } else {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        -1
      );
      this._timeOut = setTimeout(this.capitalizeMonth, 1);
    }
  }

  nextClicked() {
    if (
      this.calendar.currentView === STYLE_CALENDAR.DAY &&
      this.calendar.currentView !== STYLE_CALENDAR.YEAR
    ) {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
        this.calendar.activeDate,
        1
      );
    } else {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        1
      );
      this._timeOut = setTimeout(this.capitalizeMonth, 1);
    }
  }
}
