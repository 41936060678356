import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import { openModalLoading } from 'src/app/shared/service/utils';
import { BarChartConfigInterface } from 'src/utils/models/barChart';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  @Input() chartData: BarChartConfigInterface

  @Input() hasChartData = false;

  @Input() height = '400px'

  @Input() radioButton = false

  @Input() selectedRadioValue = 'daily';


  @Output() radioValueChange = new EventEmitter<string>()

  printId: string


  constructor(private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.printId = this.chartData?.id + 'wrapper'
  }

  hidenOrShowData(e) {
    const chart = Chart.getChart(this.chartData?.id);
    if (e?.dataSetIndex !== undefined && e?.dataSetIndex >= 0) {
        const meta = chart.getDatasetMeta(e.dataSetIndex);
        meta.hidden = meta.hidden === null ? !chart.data.datasets[e.dataSetIndex].hidden : null;
        chart.update();
    }
  }

  async picture(event) {
    const graphic = document.getElementById(this.printId);
    const textDownload = this.chartData.title + '.png';
    if (graphic) {
      const loading = openModalLoading(
        this.dialog,
        "Cargando",
        "Descargar el gráfico como imagen"
      );
      await html2canvas(graphic).then(function (canvas) {
				const image = canvas.toDataURL();
				const link = document.createElement('a');
				link.href = image;
				link.download = textDownload;
				link.dispatchEvent(new MouseEvent('click'));
			});
      loading.close();
    }
  }

  radioChange(event: MatRadioChange){
    this.radioValueChange.emit(event.value)
  }


}
