import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

import { MomentDateAdapter } from "@angular/material-moment-adapter";

import { CalendarPickerComponent } from "../calendar-picker.component";

import moment, { Moment } from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { HeaderComponentComponent } from "../header-component/header-component.component";

export const MONTH_MODE_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export const TIME_OUT = 600;

const datePickerClass = {
  DAY: "calendar-picker",
  MONTH: "calendar-picker-month",
  YEAR: "calendar-picker-year",
  PERIOD: "calendar-picker-period",
};

@Component({
  selector: "calendar-month",
  templateUrl: "./calendar-month.component.html",
  styleUrls: ["./calendar-month.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "pt-br" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarMonthComponent),
      multi: true,
    },
  ],
})
export class CalendarMonthComponent
  implements ControlValueAccessor, AfterViewInit
{
  headerPicker = HeaderComponentComponent;

  @Input() customFormFieldClass = "";

  /** Component label */
  @Input() label = "";

  _max: Moment;
  @Input()
  get max(): string | Date {
    return this._max ? this._max.format("MM/YYYY") : undefined;
  }
  set max(max: string | Date) {
    // expect MM to be 1..12 and YYYY > 0
    if (max) {
      const momentDate =
        typeof max === "string" ? moment(max, "MM/YYYY") : moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _min: Moment;
  @Input()
  get min(): string | Date {
    return this._min ? this._min.format("MM/YYYY") : undefined;
  }
  set min(min: string | Date) {
    // expect MM to be 1..12 and YYYY > 0
    if (min) {
      const momentDate =
        typeof min === "string" ? moment(min, "MM/YYYY") : moment(min);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _mode: "MONTH";
  @Input()
  get mode(): "MONTH" {
    return this._mode;
  }
  set mode(mode: "MONTH") {
    this._mode = mode;
  }

  @Input() touchUi = false;

  _customFilter: (d: Moment) => boolean;

  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;

  _inputCtrl: FormControl = new FormControl();
  _panelClass = datePickerClass.YEAR;

  // Function to call when the date changes.
  onChange = (monthAndYear: Date) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  /** send the focus away from the input so it doesn't open again */
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  constructor(private readonly parent: CalendarPickerComponent) {}

  ngAfterViewInit() {
    this._takeFocusAway = this.parent._takeFocusAway;
  }

  writeValue(date: Date): void {
    if (date && this._isMonthEnabled(date.getFullYear(), date.getMonth())) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        momentDate.set({ date: 1 });

        this._inputCtrl.setValue(momentDate, { emitEvent: false });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled ? this._inputCtrl.disable() : this._inputCtrl.enable();
  }

  _yearSelectedHandler(
    chosenMonthDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    if (!this._isYearEnabled(chosenMonthDate.year())) {
      datepicker.close();
      setTimeout(() => (datepicker.disabled = false), TIME_OUT);
    }
  }

  _monthSelectedHandler(
    chosenMonthDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    datepicker.close();
    if (
      !this._isMonthEnabled(chosenMonthDate.year(), chosenMonthDate.month())
    ) {
      return;
    }

    if (this._max && chosenMonthDate.diff(this._max, "month") > 0) {
      chosenMonthDate = this._max.clone();
    }

    if (this._min && this._min.diff(chosenMonthDate, "month") > 0) {
      chosenMonthDate = this._min.clone();
    }

    chosenMonthDate.set({ date: 1 });

    this._inputCtrl.setValue(chosenMonthDate);
    this.onChange(chosenMonthDate.toDate());
    this.onTouched();
  }

  /** Whether the given year is enabled. */
  private _isYearEnabled(year: number) {
    // disable if the year is greater than maxDate lower than minDate
    if (
      year === undefined ||
      year === null ||
      (this._max && year > this._max.year()) ||
      (this._min && year < this._min.year())
    ) {
      return false;
    }

    // enable if it reaches here and there's no filter defined
    if (!this._customFilter) {
      return true;
    }

    const firstOfYear = moment([year, 0, 1]);

    // If any date in the year is enabled count the year as enabled.
    for (const date = firstOfYear; date.year() === year; date.add(1, "d")) {
      if (this._customFilter(date)) {
        return true;
      }
    }

    return false;
  }

  /** Whether the given year is enabled. */
  private _isMonthEnabled(year: number, month: number) {
    if (
      month === undefined ||
      month === null ||
      this._isYearAndMonthAfterMaxDate(year, month) ||
      this._isYearAndMonthBeforeMinDate(year, month)
    ) {
      return false;
    }

    if (!this._customFilter) {
      return true;
    }

    const firstOfMonth = moment([year, month, 1]);

    // If any date in the month is enabled count the month as enabled.
    for (let date = firstOfMonth; date.month() === month; date.add(1, "d")) {
      if (this._customFilter(date)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Tests whether the combination month/year is after this.maxDate, considering
   * just the month and year of this.maxDate
   */
  private _isYearAndMonthAfterMaxDate(year: number, month: number) {
    if (this._max) {
      const maxYear = this._max.year();
      const maxMonth = this._max.month();

      return year > maxYear || (year === maxYear && month > maxMonth);
    }

    return false;
  }

  /**
   * Tests whether the combination month/year is before this.minDate, considering
   * just the month and year of this.minDate
   */
  private _isYearAndMonthBeforeMinDate(year: number, month: number) {
    if (this.min) {
      const minYear = this._min.year();
      const minMonth = this._min.month();

      return year < minYear || (year === minYear && month < minMonth);
    }

    return false;
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClass(datePickerClass.YEAR);
      datepicker.open();
    }
  }

  setPanelClass(visualization) {
    if (this._panelClass !== "") {
      this._panelClass = visualization;
    } else {
      this._panelClass = datePickerClass.YEAR;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  capitalizeMonth() {
    const htmlElement = document.getElementsByClassName(
      "mat-calendar-body-cell-content"
    ) as HTMLCollectionOf<HTMLElement>;
    Array.from(htmlElement).forEach((currentMonth) => {
      currentMonth.innerText = currentMonth.innerText.toLowerCase();
      currentMonth.innerText =
        currentMonth.innerText.charAt(0).toUpperCase() +
        currentMonth.innerText.slice(1);
    });
  }
  changePanelClass(evt) {
    if (evt === "multi-year") {
      this.setPanelClass(datePickerClass.YEAR);
    } else {
      this.setPanelClass(datePickerClass.MONTH);
      this.capitalizeMonth();
    }
  }
}
